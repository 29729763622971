// src/components/ConventionItem.tsx

import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { getStateOrTerritoryByKey } from "../System/StateSelect";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Convention } from "../../api/ApiModels/Models";

interface ConventionListItemProps {
  item: Convention;
  attendConvention: (conventionId: number) => void;
}

const ConventionListItem: React.FC<ConventionListItemProps> = ({
  item,
  attendConvention,
}) => {
  const startDate = new Date(item.startDate);
  const day = startDate.getDate().toString().padStart(2, "0");
  const month = startDate
    .toLocaleString("default", { month: "short" })
    .toUpperCase();
  const { user } = useAuth0();
  const navigate = useNavigate();

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-1 text-center">
            <div className="date-container">
              <div className="day"> <h3>{day}</h3></div>
              <div className="month">{month}</div>
            </div>
          </div>
          <div className="col-10">
            <h4>{item.name}</h4>
            <div className="subtitle">
              {item.location.address}, {item.location.city},{" "}
              {getStateOrTerritoryByKey(Number(item.location.state))}
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <ButtonGroup className="d-flex w-100">
          <button
            style={{ borderTopLeftRadius: "0" }}
            className="flex-fill btn btn-info"
          >
            Website
          </button>
          <button
            onClick={() => attendConvention(item.id)}
            className="flex-fill btn btn-info"
          >
            Attend
          </button>
          <button
            onClick={() => navigate(`/conventions/${item.id}`)}
            style={{ borderTopRightRadius: "0" }}
            className="flex-fill btn btn-info"
          >
            Details
          </button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default ConventionListItem;
