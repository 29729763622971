import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import { Navbar } from 'react-bootstrap'
import { DownArrowIcon, NotificationsIcon } from '../common/Icons'
import Sidebar from './Sidebar'
import { useNavigate } from 'react-router-dom'
import { GetUserDisplayName } from '../../Helpers/Auth0Helper'

const DesktopNavBar: React.FC = () => {
  const { isAuthenticated, user, isLoading } = useAuth0()
  const [requiresOnboarding, setRequiresOnboarding] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      setRequiresOnboarding(
        user['https://cosplaycorps.com/CosplayCorpsId'] === 0,
      )
    }
  }, [user, isAuthenticated, isLoading])

  return !requiresOnboarding ? (
    <div className="position-relative z-100">
      <Navbar className=" position-fixed w-100 bg-white z-50   d-flex justify-content-between border-bottom rounded-0 px-3 px-lg-5">
        <Navbar.Brand href="/">
          <img className="nav-logo" src="/images/CosplayCorpsLogo.png" alt="cosplay" />
        </Navbar.Brand>

        <div className="d-flex justify-content-center align-items-center gap-2">
          {/* <button className="btn">
            <NotificationsIcon />
          </button> */}
          <button onClick={()=> navigate("/Profile") } className="d-flex btn gap-3 align-items-center bg-light-blue p-2 rounded-5 ">
            <img
              className="avatar"
              src={user?.picture}
              alt="cosplay"
            />
            <span className="d-none d-lg-flex">{GetUserDisplayName(user!)}</span>
            {/* <DownArrowIcon /> */}
          </button>
        </div>
      </Navbar>
      <Sidebar isAuthenticated={isAuthenticated} user={user} />
    </div>
  ) : (
    <></>
  )
}

export default DesktopNavBar
