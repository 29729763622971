import React from "react";

const OurFeatures = () => {
  return (
    <div className="my-5 custom-container mx-auto px-4 px-xl-0">
      <h2 className="text-center mb-4 dark text-xl ff-anton font-normal mt-3 pt-5 pb-4">
        Our Features
      </h2>
      <div className="d-xl-flex  gap-3">
        <div className="d-md-flex gap-3 justify-content-center">
          <div
            className="feature-img position-relative  "
            style={{
              backgroundImage: `url('/images/quickshoots.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "white",
              height: "718px",
              width: "413px",
            }}
          >
            <div className="feature-box ">
              <img src="/svg/icons1.svg" alt="icons1" />
              <h3 className="text-lg ff-anton font-normal dark text-center pt-4">
                Quick Shoots
              </h3>
              <p className="text-s  font-normal light ff-manrope text-center">
                Schedule and manage photoshoots with ease. Our Quick Shoots
                feature ensures you never miss a moment.
              </p>
            </div>
          </div>
          <div
            className="feature-img position-relative d-block d-xl-none"
            style={{
              backgroundImage: `url('/images/tracking.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "white",
              height: "718px",
              width: "413px",
            }}
          >
            <div className="feature-box ">
              <img src="/svg/icon4.svg" alt="icons1" />
              <h3 className="text-lg ff-anton font-normal dark text-center pt-4">
                Metrics Tracking
              </h3>
              <p className="text-s  font-normal light ff-manrope text-center">
                Track your performance and engagement. Get detailed insights to
                help you improve and grow your presence.
              </p>
            </div>
          </div>
        </div>
        <div className="d-md-flex flex-xl-column justify-content-center gap-3">
          <div
            className="feature-img position-relative"
            style={{
              backgroundImage: `url('/images/subsrcibes.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "white",
              height: "349px",
              width: "413px",
            }}
          >
            <div className="feature-box ">
              <img src="/svg/icon2.svg" alt="icons1" />
              <h3 className="text-lg ff-anton font-normal dark text-center pt-4">
                Subscribers
              </h3>
              <p className="text-s  font-normal light ff-manrope text-center">
                Unlock exclusive content and features for subscribers. Grow your
                audience and offer them more value.
              </p>
            </div>
          </div>
          <div
            className="feature-img position-relative"
            style={{
              backgroundImage: `url('/images/lowrates.png')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "white",
              height: "349px",
              width: "413px",
            }}
          >
            <div className="feature-box ">
              <img src="/svg/icon3.svg" alt="icons1" />
              <h3 className="text-lg ff-anton font-normal dark text-center pt-4">
                Low Rates
              </h3>
              <p className="text-s  font-normal light ff-manrope text-center">
                Enjoy all these features at competitive rates. We believe in
                supporting our community with affordable options.
              </p>
            </div>
          </div>
        </div>
        <div
          className="feature-img position-relative d-none d-xl-block"
          style={{
            backgroundImage: `url('/images/tracking.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            height: "718px",
            width: "413px",
          }}
        >
          <div className="feature-box ">
            <img src="/svg/icon4.svg" alt="icons1" />
            <h3 className="text-lg ff-anton font-normal dark text-center pt-4">
              Metrics Tracking
            </h3>
            <p className="text-s  font-normal light ff-manrope text-center">
              Track your performance and engagement. Get detailed insights to
              help you improve and grow your presence.
            </p>
          </div>
        </div>
      </div>
      <a href="hKFo2SA4QXgzMFM3SFZEMFZncU9YNUQ1eFRneHBwZDI1SXM0b6Fur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFMyRzJvWmdlalFZZ1RvMjlaSUVVZlFEbUxPUW54TW1Do2NpZNkgajFxVDZaTUFoTXdCS0FWcW9pM2ZPaDRLSWh3a1FUM24">
        <div className="text-center mt-4">
          <button className="joinnow text-s ff-manrope font-semibold mb-sm-5 mt-sm-3">
            Join Now
          </button>
        </div>
      </a>
    </div>
  );
};

export default OurFeatures;
