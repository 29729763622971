import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

// Import required Swiper modules
import { Navigation } from "swiper/modules";
import { SliderLeftArrowIcon, SliderRightArrowIcon } from "../common/Icons";
import { Photograph } from "../../pages/Photographs/Models/Photograph";
import { useLocation, useNavigate } from "react-router-dom";

interface MyImagesProps {
  showBottomText: boolean;
  headingText: string;
  images: Photograph[] | null
}

const MyImages: React.FC<MyImagesProps> = ({ showBottomText, headingText, images }) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [isPrevButtonEnabled, setIsPrevButtonEnabled] = useState(true);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handlePrevClick = () => {
    setIsPrevButtonEnabled(false);
    setIsNextButtonEnabled(true);
    swiperRef.current?.slidePrev();
  };

  const handleNextClick = () => {
    setIsNextButtonEnabled(false);
    setIsPrevButtonEnabled(true);
    swiperRef.current?.slideNext();
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on("slideChange", () => {
        const swiperInstance = swiperRef.current!;
        setIsPrevButtonEnabled(swiperInstance.isBeginning);
        setIsNextButtonEnabled(swiperInstance.isEnd);
      });
    }
  }, []);

  if(images?.length === 0){
    return <></>
  }
  return (!images ? <div className="container container-custom mb-lg-0 mb-4 mt-lg-5">
    <div className="position-relative profile-box-main-lg p-lg-4">
      <h4 className="mb-4 common-head">
        {headingText}
      </h4>
      <div className="text-center">
        No Images
      </div>
    </div></div> :
    <div className="container container-custom mb-lg-0 mb-4 mt-lg-5">
      <div className="position-relative profile-box-main-lg p-lg-4">
        <h4 className="mb-4 common-head">
          {headingText}
        </h4>
        <div className="d-none d-md-flex position-absolute items-center w-full pt-4 pe-4 gap-4 z-10 end-0 top-0">
          <button
            onClick={handlePrevClick}
            className={`custom-prev-button border-0 me-1.5 bg-transparent ${isPrevButtonEnabled ? "w-[30px]" : "w-[40px]"}`}
          >
            <span
              className={isPrevButtonEnabled ? "opacity-40" : "opacity-100"}
            >
              <SliderLeftArrowIcon />
            </span>
          </button>
          <button
            onClick={handleNextClick}
            className={`custom-next-button border-0 bg-transparent ${isNextButtonEnabled ? "" : ""
              } `}
          >
            <span
              className={isPrevButtonEnabled ? "opacity-100" : "opacity-40"}
            >
              <SliderRightArrowIcon />
            </span>
          </button>
        </div>
        <Swiper
          modules={[Navigation]}
          slidesPerView={1.5}
          spaceBetween={16}
          className="my-swiper"
          onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 4.8,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 4.8,
              spaceBetween: 16,
            },
          }}
        >
          {images && images.map((image) =>
          (
            <SwiperSlide key={image.id}>
              <div className="image-wrapper rounded-4" onClick={()=> navigate(`/photo/${image.id}`, { state: { from: location } }) }>
                <img
                  src={"https://storage.cosplaycorps.com" + image.imageUri}
                  alt={image.description}
                  width={218}
                  height={218}
                  className="rounded-4"
                />
                {/* {showBottomText && (
                  <div className="bottom-text mt-2 ff-manrope font-medium text-sm">
                    432 Views In The Last <br /> 7 Days
                    <br />
                    <span className="pink">5% ↑</span>
                  </div>
                )} */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default MyImages;
