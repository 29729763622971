import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { DashBoardThreeDotsIcon } from '../../components/common/Icons'
import AvatarGroup from '../../components/common/AvatarGroup'
import "../../components/common/AvatarGroup.css"

interface ConventionCardProps {
  id: number
  title: string
  type: string
  date: string
  image: string
  // avatars: string[]
}

const ConventionCard: React.FC<ConventionCardProps> = ({
  title,
  type,
  date,
  image,
  // avatars,
}) => {
  return (
    <div className="convention-tab-box p-3 mt-sm-4 mt-3 mx-auto mx-lg-0">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <img src={image} alt={title} className="carnival-img me-1" />
          <p className="ff-manrope fw-semibold text-s mb-0 dark">{title}</p>
        </div>
        <Dropdown>
          <Dropdown.Toggle as="span" className="cursor-pointer">
            <DashBoardThreeDotsIcon />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item>Delete</Dropdown.Item>
            {/* Add more actions as needed */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <div className="ff-manrope fw-medium text-xs gray">
          <p className="pt-14 mb-0">Type: {type}</p>
          <p className="pt-14 mb-0">Date: {date}</p>
        </div>
        {/* <AvatarGroup images={avatars} /> */}
      </div>
    </div>
  )
}

export default ConventionCard
