import useSWRInfinite from 'swr/infinite';
import PhotoApiProcessor from '../../../api/PhotoApiProcessor';
import { Photograph } from '../../../pages/Photographs/Models/Photograph';
import { useAuth0 } from '@auth0/auth0-react';

const uniquePhotos = (photos: Photograph[]) => {
  if(photos == undefined || photos == null || photos.length == 0){
    return [];
  }
  const uniquePhotoMap = new Map();
  photos.forEach(photo => {
    if(!photo){
      return;
    }
    if (!uniquePhotoMap.has(photo.id)) {
      uniquePhotoMap.set(photo.id, photo);
    }
  });
  return Array.from(uniquePhotoMap.values());
};

export const useInfinitePhotos = (filterString?: string, photoShootId?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const photoApiProcessor = new PhotoApiProcessor(getAccessTokenSilently);

  const getKey = (pageIndex: number, previousPageData: any) => {
    if (previousPageData && !previousPageData.photos.length) return null; // reached the end
    return `${process.env.REACT_APP_COSPLAYCORPS_API_URL}/Photographs?page=${pageIndex + 1}&numPerPage=10`; // API endpoint with pagination
  };

  const fetcher = async (key: string) => {
    if(photoShootId){
        var data = await photoApiProcessor.getPhotosByQuickShootId(photoShootId);
        return {
            photos : data,
            totalPages: 1
        }
    }
    return await photoApiProcessor.fetchPhotos(key);
  };

  const { data, error, size, setSize } = useSWRInfinite(getKey, fetcher);

  const photos = data ? [].concat(...data.map(page => page.photos)) : [];
  const totalPages = data ? data[0]?.totalPages : 1;
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
  if(data === undefined || data === null){
    return {
      photos: uniquePhotos(photos) as Photograph[],
      totalPages,
      isLoadingMore,
      isReachingEnd : true,
      size,
      setSize,
      error,
    };
  }
  // const isReachingEnd = data && data[data.length - 1]?.photos.length === 0;

  return {
    photos: uniquePhotos(photos) as Photograph[],
    totalPages,
    isLoadingMore,
    isReachingEnd: true,
    size,
    setSize,
    error,
  };
};

