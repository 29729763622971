// src/components/LoginButton.tsx

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface LoginButtonProps{
  alternateText?: string;
  className? : string;
}

const LoginButton: React.FC<LoginButtonProps> = ({ alternateText, className}) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      className={className ?? "logout-btn d-flex justify-content-center align-items-center gap-2"}
      onClick={() => loginWithRedirect()}
    >
      {!alternateText && 
      <span>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_91_320)">
            <path
              d="M17.4371 8.43795H10.3122C10.0017 8.43795 9.74976 8.18596 9.74976 7.87547C9.74976 7.56498 10.0017 7.31299 10.3122 7.31299H17.4371C17.7476 7.31299 17.9995 7.56498 17.9995 7.87547C17.9995 8.18596 17.7476 8.43795 17.4371 8.43795Z"
              fill="#FE2A88"
            />
            <path
              d="M14.6246 11.2502C14.4805 11.2502 14.3366 11.1954 14.227 11.0852C14.0073 10.8654 14.0073 10.5091 14.227 10.2894L16.642 7.87457L14.227 5.45958C14.0073 5.23986 14.0073 4.88364 14.227 4.66392C14.4469 4.44406 14.8031 4.44406 15.0228 4.66392L17.8353 7.47633C18.055 7.69605 18.055 8.05227 17.8353 8.27199L15.0228 11.0844C14.9126 11.1954 14.7687 11.2502 14.6246 11.2502Z"
              fill="#FE2A88"
            />
            <path
              d="M5.99986 17.9999C5.83932 17.9999 5.68703 17.9774 5.53488 17.9302L1.02142 16.4265C0.407305 16.212 0 15.6397 0 15.0001V1.50049C0 0.673243 0.672754 0.000488281 1.5 0.000488281C1.66039 0.000488281 1.81269 0.0230096 1.96498 0.0702493L6.4783 1.57396C7.09255 1.78846 7.49972 2.36069 7.49972 3.00035V16.4999C7.49972 17.3272 6.8271 17.9999 5.99986 17.9999ZM1.5 1.12545C1.29374 1.12545 1.12496 1.29423 1.12496 1.50049V15.0001C1.12496 15.1598 1.23222 15.3082 1.3852 15.3615L5.8775 16.8585C5.90977 16.8689 5.95179 16.875 5.99986 16.875C6.20612 16.875 6.37475 16.7062 6.37475 16.4999V3.00035C6.37475 2.84064 6.2675 2.69219 6.11452 2.63891L1.62222 1.14193C1.58995 1.1315 1.54793 1.12545 1.5 1.12545Z"
              fill="#FE2A88"
            />
            <path
              d="M11.4371 6.00035C11.1266 6.00035 10.8746 5.74836 10.8746 5.43786V2.06297C10.8746 1.54622 10.454 1.12545 9.93722 1.12545H1.49998C1.18949 1.12545 0.9375 0.873462 0.9375 0.562971C0.9375 0.252479 1.18949 0.000488281 1.49998 0.000488281H9.93722C11.075 0.000488281 11.9996 0.925234 11.9996 2.06297V5.43786C11.9996 5.74836 11.7476 6.00035 11.4371 6.00035Z"
              fill="#FE2A88"
            />
            <path
              d="M9.93724 15.7499H6.93724C6.62675 15.7499 6.37476 15.4979 6.37476 15.1874C6.37476 14.8769 6.62675 14.6249 6.93724 14.6249H9.93724C10.454 14.6249 10.8746 14.2041 10.8746 13.6874V10.3125C10.8746 10.002 11.1266 9.75 11.4371 9.75C11.7476 9.75 11.9996 10.002 11.9996 10.3125V13.6874C11.9996 14.8251 11.075 15.7499 9.93724 15.7499Z"
              fill="#FE2A88"
            />
          </g>
          <defs>
            <clipPath id="clip0_91_320">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>}
      {alternateText ?? "Log In"}
    </button>
  );
};

export default LoginButton;
