// src/pages/QuickShootSearch.tsx
import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { QuickShoot, QuickShootStatus } from '../../interfaces/QuickShoot';
import { getStatusLabel } from '../../Helpers/QuickShootHelper';

interface QuickShootSearchProps {
    quickShoot?: QuickShoot | null;
}

const QuickShootSearch: React.FC<QuickShootSearchProps> = ({ quickShoot }) => {

  const getProgressPercentage = (status: QuickShootStatus) => {
    const totalSteps = Object.keys(QuickShootStatus).length / 2; // Adjust for enum with numeric keys
    return (status / (totalSteps - 1)) * 100;
  };


  return (
    <>     
      {quickShoot && quickShoot.status !== null && quickShoot.status !== QuickShootStatus.Complete && (
        <>
        <div className='row'>
            <div className='col pb-3 text-center'>
            <h3>Quick Shoot Status for {quickShoot.conventionName}</h3>
            </div>
            
        </div>
        <div className="progress-container">
          <ProgressBar striped variant="success"
            now={getProgressPercentage(quickShoot!.status)}
            label={`${getProgressPercentage(quickShoot!.status)}%`}
            className="progress-bar"
            max={100}
            min={0}
          />
          <div className="progress-label">
            {getStatusLabel(quickShoot!.status)}
          </div>
        </div>

        <div className='row'>
            <div className='col-12 text-center'>
                Check back in a few days to see if the progress has updated!
            </div>
        </div>
        </>
      )}

    </>
  );
};

export default QuickShootSearch;