 const Privacy = ()=>{
    return (<>
    <h3>Legal Things</h3>

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <iframe
        src="https://app.termly.io/policy-viewer/policy.html?policyUUID=0e8557b2-8b02-46f1-9d08-949e09fdfb59" // Replace with your desired URL
        title="Disclaimer"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </div>
    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <iframe
        src="https://app.termly.io/policy-viewer/policy.html?policyUUID=581827e5-f494-4261-9346-d4d7f8b006dc" // Replace with your desired URL
        title="Privacy Policy"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
    </div>


    </>)
}
export default Privacy;