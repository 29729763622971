import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Callback from "./components/Auth/callback";
import PrivateRoute from "./components/Auth/PrivateRoute";
import DashboardLayout from "./hoc/DashboardLayout";
import Conventions from "./pages/Conventions/Conventions";
import Privacy from "./pages/Home/Privacy";
import PhotoDetails from "./pages/Photographs/PhotoDetails";
import PhotoGallery from "./pages/Photographs/PhotoGallery";
import PhotoUpload from "./pages/Photographs/PhotoUpload";
import ReserveQuickShoot from "./pages/PhotoShoots/QuickShoot";
import QuickShootSearch from "./pages/PhotoShoots/QuickShootSearch";
import SignUpWizard from "./pages/Profile/SignUpWizard";
import Profile from "./pages/User/Profile";
import MyDashboard from "./pages/User/MyDashboard";
import LandingPage from "./pages/Home/LandingPage";
import "../src/App.scss";
import ConventionDetails from "./pages/Conventions/ConventionDetails";
import MobileBottomNavbar from "./components/System/MobileBottomNavbar";
import Booking from "./pages/Photographs/Booking";
import { CreatePhotoShootBooking } from "./pages/User/CreatePhotoShootBooking";
import UnauthorizedPage from "./pages/UnauthorizedPage";

const App = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const location = useLocation();
  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <>
      <ToastContainer position="top-right" />

      {!isAuthenticated && location.pathname === "/" ? (
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      ) : (
        <DashboardLayout>
          <Routes>
            <Route path="/" element={<PhotoGallery />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
            <Route path="/sign-up-wizard" element={<SignUpWizard />} />
            {isAuthenticated &&
              user &&
              user["https://cosplaycorps.com/CosplayCorpsId"] != 0 && (
                <Route path="/profile" element={<Profile />} />
              )}
            {isAuthenticated &&
              user &&
              user["https://cosplaycorps.com/CosplayCorpsId"] == 3 && (
                <Route path="/booking" element={<Booking />} />
              )}
            {isAuthenticated &&
              user &&
              user["https://cosplaycorps.com/CosplayCorpsId"] != 0 && (
                <Route path="/dashboard" element={<MyDashboard />} />
              )}
            <Route
              path="/dashboard"
              element={<PrivateRoute component={MyDashboard} />}
            />
            <Route path="/callback" element={<Callback />} />
            <Route
              path="/photo/:id"
              element={<PrivateRoute component={PhotoDetails} />}
            />
            <Route
              path="/Gallery"
              element={<PrivateRoute component={PhotoGallery} />}
            />
            {isAuthenticated &&
              user &&
              user["https://cosplaycorps.com/CosplayCorpsId"] == 3 && (
                <Route
                  path="/conventions"
                  element={<PrivateRoute component={Conventions} />}
                />
              )}
            {isAuthenticated &&
              user &&
              user["https://cosplaycorps.com/CosplayCorpsId"] == 3 && (
                <Route
                  path="/createbooking/:conventionId"
                  element={<PrivateRoute requiredRoles={["Photographer", "Admin"]} component={CreatePhotoShootBooking} />}
                />
              )}
            <Route
              path="/conventions/:id"
              element={<PrivateRoute component={ConventionDetails} />}
            />
            <Route
              path="/upload"
              element={<PrivateRoute component={PhotoUpload} />}
            />
            <Route
              path="/upload/:conventionId/:referenceCode"
              element={<PrivateRoute component={PhotoUpload} />}
            />
            <Route
              path="/adhocPhotoShoot"
              element={<PrivateRoute component={ReserveQuickShoot} />}
            />
            <Route
              path="/profile"
              element={<PrivateRoute component={Profile} />}
            />
            <Route path="/user/:username" element={<Profile />} />
            {/* <Route
              path="/mydashboard"
              element={<PrivateRoute component={MyDashboard} />}
            /> */}
            <Route
              path="/quickshootQR/:quickShootId"
              element={<PrivateRoute component={QuickShootSearch} />}
            />
            <Route
              path="/quickshoot/:conventionId/:referenceCode"
              element={<PrivateRoute component={PhotoGallery} />}
            />
            <Route
              path="/searchQuickShoot"
              element={<PrivateRoute component={QuickShootSearch} />}
            />
          </Routes>
        </DashboardLayout>
      )}
      <div className="d-lg-none position-fixed w-100 bottom-0 z-100 bg-light shadow-lg">
        <MobileBottomNavbar />
      </div>
    </>
  );
};

export default App;
