import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { LeftArrowIcon, RightArrowIcon } from "../../components/common/Icons";
import { QuickShoot, QuickShootStatus } from "../../interfaces/QuickShoot";
import QuickShootCard from "./QuickShootCard";
import QuickShootsApi from "../../api/QuickShootsApi";
import { useAuth0 } from "@auth0/auth0-react";

interface PhotoShootTabProps {
  quickShootData: QuickShoot[] | null;
}

const PhotoshootTab: React.FC<PhotoShootTabProps> = ({ quickShootData }) => {
  const { getAccessTokenSilently } = useAuth0();
  const quickShootApi = new QuickShootsApi(getAccessTokenSilently);
  const [quickShoots, setQuickShoots] = useState<QuickShoot[] | null>();
  const swiperRef = useRef<SwiperType | null>(null);
  const [isPrevButtonEnabled, setIsPrevButtonEnabled] = useState(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null
  );
  const [quickShootUpdateValue, setQuickShootUpdateValue] =
    useState<QuickShootStatus>(QuickShootStatus.NotStarted);

  const handlePrevClick = () => {
    swiperRef.current?.slidePrev();
  };

  const handleNextClick = () => {
    swiperRef.current?.slideNext();
  };

  useEffect(() => {
    setQuickShoots(quickShootData);
    if (swiperRef.current) {
      swiperRef.current.on("slideChange", () => {
        const swiperInstance = swiperRef.current!;
        setIsPrevButtonEnabled(!swiperInstance.isBeginning);
        setIsNextButtonEnabled(!swiperInstance.isEnd);
      });
    }
  }, [quickShootData]);

  const handleDeleteClick = (index: number) => {
    setSelectedItemIndex(index);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedItemIndex !== null && quickShoots) {
      // Perform the delete action here (e.g., update state, call API)
      console.log(`Deleting item at index ${selectedItemIndex}`);
      await quickShootApi.deleteQuickShoot(quickShoots[selectedItemIndex].id);

      delete quickShoots[selectedItemIndex];
      // Close the modal
      setShowDeleteModal(false);
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleUpdateClick = (index: number) => {
    if (quickShoots == null) {
      return;
    }
    setSelectedItemIndex(index);
    setQuickShootUpdateValue(quickShoots[index].status);
    setShowUpdateModal(true);
  };

  const handleConfirmUpdate = async () => {
    if (selectedItemIndex !== null && quickShoots) {
      // Perform the delete action here (e.g., update state, call API)
      if (
        quickShootUpdateValue == null ||
        quickShoots[selectedItemIndex].status == quickShootUpdateValue
      ) {
        setShowUpdateModal(false);
        setQuickShootUpdateValue(QuickShootStatus.NotStarted);
        return;
      }

      quickShoots[selectedItemIndex].status = quickShootUpdateValue;
      setQuickShoots(quickShoots);
      await quickShootApi.updateQuickShootStatus(
        quickShoots[selectedItemIndex].id,
        quickShootUpdateValue
      );
      setQuickShootUpdateValue(QuickShootStatus.NotStarted);
      // Close the modal
      setShowUpdateModal(false);
    }
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case "1":
        setQuickShootUpdateValue(QuickShootStatus.EditingInProgress);
        break;
      case "2":
        setQuickShootUpdateValue(QuickShootStatus.Complete);
        break;
      default:
        setQuickShootUpdateValue(QuickShootStatus.NotStarted);
        break;
    }
  };
  if (!quickShoots) {
    return <>Loading...</>;
  }
  if (quickShoots.length == 0) {
    return (
      <div className="text-center">
        <h3>No Photoshoots</h3>
      </div>
    );
  }
  return (
    <div className="container px-0 custom-container">
      <h3 className="ff-manrope fw-semibold text-md dark mt-5 pt-md-2 pt-lg-3">
        Photo Shoots
      </h3>
      <div className="swiper-container d-xl-none">
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          onSwiper={(swiper: SwiperType) => {
            swiperRef.current = swiper;
          }}
          autoplay={{ delay: 15000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          spaceBetween={10}
          slidesPerView={1.1}
          breakpoints={{
            320: { slidesPerView: 1, spaceBetween: 10 },
            480: { slidesPerView: 1.5, spaceBetween: 15 },
            640: { slidesPerView: 2, spaceBetween: 20 },
            768: { slidesPerView: 2.3, spaceBetween: 20 },
            992: { slidesPerView: 2.5, spaceBetween: 20 },
          }}
        >
          {quickShoots.map((item, index) => (
            <SwiperSlide key={index}>
              <QuickShootCard
                imageSrc={"/images/men-on-mountain-2.png"}
                quickShoot={item}
                onDeleteClick={() => handleDeleteClick(item.id)}
                onUpdateClick={() => handleUpdateClick(item.id)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* Custom Navigation for Swiper */}
        <div className="pt-4 mx-auto d-flex justify-content-center text-center">
          <div className="d-flex d-md-none position-absolute items-center justify-content-center w-100 pt-5 pe-4 gap-4 z-10 mx-auto left-50 bottom-0">
            <button
              onClick={handlePrevClick}
              className={`custom-prev-button border-0 me-1.5 bg-transparent ${
                isPrevButtonEnabled ? "" : "disabled"
              }`}
            >
              <span
                className={isPrevButtonEnabled ? "opacity-100" : "opacity-40"}
              >
                <LeftArrowIcon />
              </span>
            </button>
            <button
              onClick={handleNextClick}
              className={`custom-next-button border-0 bg-transparent ${
                isNextButtonEnabled ? "" : "disabled"
              } `}
            >
              <span
                className={isNextButtonEnabled ? "opacity-100" : "opacity-40"}
              >
                <RightArrowIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        className="d-none d-xl-block"
        style={{
          maxHeight: "450px",
          overflowY: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <Row>
          {quickShoots.map((item, index) => (
            <Col md={4} key={index}>
              <QuickShootCard
                imageSrc={"/images/men-on-mountain-2.png"}
                quickShoot={item}
                onDeleteClick={() => handleDeleteClick(index)}
                onUpdateClick={() => handleUpdateClick(index)}
              />
            </Col>
          ))}
        </Row>
      </div>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Quickshoot</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        value={quickShootUpdateValue}
        show={showUpdateModal}
        onHide={handleCloseUpdateModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select onChange={handleSelectChange} className="form-select">
            <option value={QuickShootStatus.NotStarted}>Not Started</option>
            <option value={QuickShootStatus.EditingInProgress}>
              In Progress
            </option>
            <option value={QuickShootStatus.Complete}>Complete</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PhotoshootTab;
