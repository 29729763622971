import React from 'react'
import { DownloadIcon, EyeOpenIcon } from '../../components/common/Icons'
import { QuickShoot, QuickShootStatus } from '../../interfaces/QuickShoot'
import { getStatusLabel } from '../../Helpers/QuickShootHelper'
import QuickShootsApi from '../../api/QuickShootsApi'
import PhotoApiProcessor from '../../api/PhotoApiProcessor'
import { useAuth0 } from '@auth0/auth0-react'
import { GetCosplayCorpsUserFromToken } from '../../Helpers/Auth0Helper'
import { FaUpload } from 'react-icons/fa'

interface QuickShootCardProps {
  quickShoot: QuickShoot,
  imageSrc: string,
  onDeleteClick: () => void,
  onUpdateClick: () => void,
}

const QuickShootCard: React.FC<QuickShootCardProps> = ({
  imageSrc,
  onDeleteClick,
  onUpdateClick,
  quickShoot
}) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const photoApi = new PhotoApiProcessor(getAccessTokenSilently);
  const ccUser = GetCosplayCorpsUserFromToken(user);
  return (
    <div className="max-w-369 pb-5 mx-auto mx-lg-0">
      <div
        className={`photoshoot-box mt-4 ${quickShoot.status !== QuickShootStatus.Complete ? 'incomplete-box' : ''
          }`}
      >

        <div className="d-flex align-items-center justify-content-between">
          <div>{quickShoot.instagramHandle}
          </div>
          <div>{quickShoot.referenceCode}</div>
        </div>
          <img
            src={imageSrc}
            alt="photoshoot"
            className="moutain-men-image w-100 h-100 mb-1"
          />
        <div className="d-flex align-items-center justify-content-between mt-3">
          <div
            className={`ff-manrope fw-medium text-xs btn-complete ${quickShoot.status === QuickShootStatus.Complete
              ? 'btn-complete-color'
              : 'btn-incomplete-color'
              }`}
          >
            {getStatusLabel(quickShoot.status)}
          </div>
          <div className="d-flex align-items-center gap-3">
            {
              quickShoot.status === QuickShootStatus.Complete &&
              <>
                <button onClick={() => photoApi.downloadQuickShootImagesByQuickShootId(quickShoot.id, GetCosplayCorpsUserFromToken(user)?.Id)} className="cursor-pointer btn p-0">
                  <DownloadIcon />
                </button>
                <a href={`/quickshootQR/${quickShoot.id}`} className="cursor-pointer btn p-0">
                  <EyeOpenIcon />
                </a>
                {
                  ccUser?.Id == quickShoot.userId &&
                  <a href={`/upload/${quickShoot.conventionId}/${quickShoot.referenceCode}`}>
                    <FaUpload style={{color: 'black'}}/>
                  </a>
                }
              </>
            }
            {
              ccUser?.Id == quickShoot.userId && quickShoot.status !== QuickShootStatus.Complete &&
              <>
                <button onClick={onDeleteClick} className="cursor-pointer btn p-0">
                  Delete
                </button>
              </>
            }
          </div>
        </div>
      </div>
      {ccUser?.Id == quickShoot.userId && <button
        className="d-flex justify-content-center w-100 btn-delete ff-manrope fw-semibold text-xs pink bg-white"
        onClick={onUpdateClick}
      >
        UPDATE
      </button>}
    </div>
  )
}

export default QuickShootCard
