import React, { useState, useCallback } from 'react';
import MapKitMap from '../../components/MapKitMap';
import ConventionFilters from '../../components/Convention/ConventionFilter';
import { useConventions } from '../../components/System/SWR/useConvention';
import ConventionApi from '../../api/ConventionApi';
import { useAuth0 } from '@auth0/auth0-react';
import ConventionEventsList from '../../components/Convention/eventsList';

const Conventions: React.FC = () => {
  const [conventionFilters, setConventionFilters] = useState<Record<string, any>>({});
  const { conventions, error, isLoading, mutate } = useConventions(conventionFilters);
  const mapKitToken = process.env.REACT_APP_APPLE_MAPKIT_TOKEN!;
  const { getAccessTokenSilently } = useAuth0();
  const conventionApi = new ConventionApi(getAccessTokenSilently);

  const handleBoundsChange = useCallback((bounds: { north: number; south: number; east: number; west: number }) => {
    if (!conventionFilters.ignoreMap) {
      setConventionFilters((prevFilters) => ({ ...prevFilters, ...bounds }));
    }
  }, [conventionFilters.ignoreMap]);

  const attendConvention = (conventionId: number) => {
    conventionApi.attendConvention(conventionId, 0, true);
  }

  const handleFilterChange = (newFilters: { name?: string; startDate?: string; endDate?: string; state?: string; ignoreMap?: boolean }) => {
    setConventionFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  return (
    <div className="container">
      <h2>Conventions</h2>
      <div className="map-container d-flex justify-content-center">
        <MapKitMap
          token={mapKitToken}
          conventions={conventions}
          ignoreMap={conventionFilters.ignoreMap || false}
          onBoundsChange={handleBoundsChange}
        />
      </div>
      <div className="content-wrapper row mt-lg-3">
        <div className="filter-area col-4">
          <div className='card'>
            <div className='card-body'>
              <h4>Filters</h4>
              <ConventionFilters onFilterChange={handleFilterChange} />
            </div>
          </div>
        </div>
        <div className="events-list col-8">
          <ConventionEventsList
            conventions={conventions}
            attendConvention={attendConvention}
          />
        </div>
      </div>
    </div>
  );
};

export default Conventions;