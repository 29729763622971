import React, { useState, useEffect } from "react";
import "./weathercomponent.css"; // You will create this CSS file for styling
import moment from "moment";
import {
  FaBolt,
  FaCloud,
  FaCloudRain,
  FaCloudShowersHeavy,
  FaCloudSun,
  FaExclamationTriangle,
  FaSmog,
  FaSnowflake,
  FaSun,
  FaTemperatureHigh,
  FaTemperatureLow,
  FaWind,
} from "react-icons/fa";
import ConventionApi from "../../api/ConventionApi";
import { useAuth0 } from "@auth0/auth0-react";
import { WeatherData } from "../../api/ApiModels/Models";
// import { fetchWeatherForConventionDates } from './weatherApi'; // Mock or real API function to fetch weather data

interface HorizontalWeatherForecastProps {
  latitude: number;
  longitude: number;
  startDate: string; // Start date of the convention (YYYY-MM-DD)
  endDate: string; // End date of the convention (YYYY-MM-DD)
}

const WeatherIcon = (conditionCode: string) => {
  switch (conditionCode) {
    case "Clear":
      return <FaSun title="Clear" />;
    case "PartlyCloudy":
    case "MostlyClear":
      return <FaCloudSun title="Partly Cloudy" />;
    case "MostlyCloudy":
    case "Cloudy":
      return <FaCloud title="Cloudy" />;
    case "Foggy":
    case "Haze":
      return <FaSmog title="Foggy/Hazy" />;
    case "Windy":
    case "Breezy":
      return <FaWind title="Windy/Breezy" />;
    case "Drizzle":
    case "Showers":
    case "ScatteredShowers":
      return <FaCloudShowersHeavy title="Drizzle/Showers" />;
    case "Rain":
    case "HeavyRain":
      return <FaCloudRain title="Rain/Heavy Rain" />;
    case "Thunderstorms":
    case "IsolatedThunderstorms":
    case "ScatteredThunderstorms":
    case "SevereThunderstorms":
      return <FaBolt title="Thunderstorms" />;
    case "Snow":
    case "HeavySnow":
    case "Flurries":
    case "BlowingSnow":
      return <FaSnowflake title="Snow" />;
    case "Sleet":
    case "FreezingRain":
    case "MixedRainAndSnow":
    case "MixedRainAndSleet":
    case "MixedSnowAndSleet":
      return <FaSnowflake title="Sleet/Freezing Rain" />;
    case "Blizzard":
      return <FaSnowflake title="Blizzard" />;
    case "Hail":
      return <FaSnowflake title="Hail" />;
    case "Tornado":
    case "Hurricane":
    case "TropicalStorm":
      return <FaWind title="Tornado/Hurricane/Tropical Storm" />;
    case "Cold":
      return <FaTemperatureLow title="Cold" />;
    case "Hot":
      return <FaTemperatureHigh title="Hot" />;
    default:
      return <FaExclamationTriangle title="Unknown Weather Condition" />;
  }
};

const WeatherChart: React.FC<HorizontalWeatherForecastProps> = ({
  latitude,
  longitude,
  startDate,
  endDate,
}) => {
  const [weatherData, setWeatherData] = useState<WeatherData[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const conventionApi = new ConventionApi(getAccessTokenSilently);

  useEffect(() => {
    const fetchWeather = async () => {
      var temp = await conventionApi.fetchConventionWeather(
        latitude,
        longitude
      );
      if (temp) {
        setWeatherData(temp);
      }
    };

    fetchWeather();
  }, [latitude, longitude, startDate, endDate]);

  return (
    <div className="weather-forecast-container">
      {weatherData.map((item, index) => (
        <div key={index} className="weather-card">
          <div className="weather-date">
            {moment(item.startDate).format("dddd")}
          </div>
          <div style={{ fontSize: "46px", background: "none" }}>
            {WeatherIcon(item.condition)}
          </div>
          {/* <img src={item.icon} alt={item.condition} className="weather-icon" /> */}
          <div className="weather-temp">{item.high}°</div>
          <div className="weather-condition">{item.condition}</div>
        </div>
      ))}
    </div>
  );
};

export default WeatherChart;
