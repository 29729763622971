import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal, Form, Card } from 'react-bootstrap';
import moment from 'moment';
import './css/Booking.css';  // Import your custom styles here

interface TimeSlot {
  start: string;
  end: string;
}

interface DaySlots {
  date: string;
  slots: TimeSlot[];
}

interface BookingProps {
  days: DaySlots[];
}

const days = [
  {
    date: "2024-09-01",
    slots: [
      { start: "10:00 AM", end: "11:00 AM" },
      { start: "11:30 AM", end: "12:30 PM" },
    ],
  },
  {
    date: "2024-09-02",
    slots: [
      { start: "1:00 PM", end: "2:00 PM" },
      { start: "2:30 PM", end: "3:30 PM" },
    ],
  },
];


const Booking: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<TimeSlot | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [contractAccepted, setContractAccepted] = useState<boolean>(false);

  const handleDateClick = (date: string) => {
    setSelectedDate(date);
    setSelectedSlot(null); // Reset selected slot when a new date is chosen
  };

  const handleSlotClick = (slot: TimeSlot) => {
    setSelectedSlot(slot);
    setShowModal(true);
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
  };

  const handleContractChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContractAccepted(e.target.checked);
  };

  const handleSubmit = () => {
    if (selectedOption && contractAccepted) {
      alert('Booking confirmed!');
      setShowModal(false);
      // Add logic to save the booking here
    } else {
      alert('Please select an option and accept the contract.');
    }
  };

  return (
    <Container>
      <h2>Book a Time Slot</h2>
      <Row className="mt-4">
        {days.map((day) => {
            const momentDate = moment(day.date);
          const weekday = momentDate.format('dddd'); // Get the weekday
          const date = momentDate.format('DD MMM'); // Get the date
          return (
            <Col key={day.date} xs={6} md={3} className="mb-3">
                <div className='row'>
                    <div className='col'>
                        {weekday}
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        {date}
                    </div>
                </div>
              {/* <Card
                className={`text-center date-card ${selectedDate === day.date ? 'selected' : ''}`}
                onClick={() => handleDateClick(day.date)}
              >
                <Card.Body>
                  <Card.Title className="weekday">{weekday}</Card.Title>
                  <Card.Text className="date">{date}</Card.Text>
                </Card.Body>
              </Card> */}
            </Col>
          );
        })}
      </Row>
      <Row className="mt-4">
        {selectedDate &&
          days
            .find((day) => day.date === selectedDate)
            ?.slots.map((slot, index) => (
              <Col key={index} xs={6} md={3} className="mb-3">
                <Button
                  variant={selectedSlot === slot ? 'success' : 'outline-success'}
                  onClick={() => handleSlotClick(slot)}
                >
                  {slot.start} - {slot.end}
                </Button>
              </Col>
            ))}
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select an Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Check
                type="radio"
                label="Option 1"
                name="option"
                value="option1"
                checked={selectedOption === 'option1'}
                onChange={handleOptionChange}
              />
              <Form.Check
                type="radio"
                label="Option 2"
                name="option"
                value="option2"
                checked={selectedOption === 'option2'}
                onChange={handleOptionChange}
              />
              <Form.Check
                type="radio"
                label="Option 3"
                name="option"
                value="option3"
                checked={selectedOption === 'option3'}
                onChange={handleOptionChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Contract</Form.Label>
              <div className="contract-text">
                <p>Please read and accept the contract before proceeding.</p>
                <p>Contract content goes here...</p>
              </div>
              <Form.Check
                type="checkbox"
                label="I accept the contract"
                checked={contractAccepted}
                onChange={handleContractChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Booking;