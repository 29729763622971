import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface ConventionAttendanceModalProps {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (attending: boolean, badgePrice?: number) => void;
  conventionName: string;
}

const ConventionAttendanceModal: React.FC<ConventionAttendanceModalProps> = ({ show, handleClose, handleSubmit, conventionName }) => {
    const [attending, setAttending] = useState<boolean>(false);
    const [badgePrice, setBadgePrice] = useState<number | null>(null);
  
    const handleConfirm = () => {
      handleSubmit(attending, badgePrice || undefined);
      handleClose();
    };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Attending {conventionName}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Check
            type="checkbox"
            label="Attending"
            name="attendance"
            id="attending"
            checked={attending}
            onChange={() => setAttending(!attending)}
          />
          {attending && (
            <Form.Group controlId="badgePrice" className="mt-3">
              <Form.Label>Care to share how much you paid for your badge?</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter badge price"
                value={badgePrice ?? 0}
                onChange={(e) => setBadgePrice(Number(e.target.value))}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm} disabled={attending && badgePrice === null}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConventionAttendanceModal;