import React from 'react';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage: React.FC = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-2); // Navigate to the page before the unauthorized page.
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Unauthorized</h1>
      <p style={styles.message}>You do not have permission to access this page.</p>
      <button onClick={goBack} style={styles.button}>Go Back</button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    textAlign: 'center' as 'center',
    color: '#333',
    padding: '20px',
  },
  title: {
    fontSize: '3rem',
    marginBottom: '20px',
  },
  message: {
    fontSize: '1.5rem',
    marginBottom: '30px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1rem',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default UnauthorizedPage;