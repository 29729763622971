import React from 'react';
import ConventionListItem from './ConventionListItem'; // Adjust the path as needed
import { Convention } from '../../api/ApiModels/Models';

interface ConventionEventsListProps {
  conventions: Convention[];
  attendConvention: (conventionId: number) => void;
  // isLoading: boolean;
}

const ConventionEventsList: React.FC<ConventionEventsListProps> = ({ conventions, attendConvention }) => {
  return (
    <div>
      {conventions.map((convention) => (
        <ConventionListItem attendConvention={attendConvention} key={convention.id} item={convention} />
      ))}
      {conventions.length == 0 && <div className='text-center'>
        <h3>
          Nothing Found
        </h3>
      </div>}
      {/* {isLoading && (
        <div className="d-flex justify-content-center mt-3">
          <Button disabled>
            Loading...
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default ConventionEventsList;