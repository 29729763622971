import React, { useEffect, useRef } from 'react';
import { getStateOrTerritoryByKey } from './System/StateSelect';
import { Convention } from '../api/ApiModels/Models';

interface MapLocationComponentProps {
  token: string;
  convention: Convention;
  ignoreMap: boolean;
}

const MapLocationComponent: React.FC<MapLocationComponentProps> = ({ token, convention, ignoreMap }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<any>(null); // Ref to store the map instance
  const annotationsRef = useRef<any[]>([]); // Ref to store the annotations

  useEffect(() => {
    const initializeMap = () => {
      if (!mapInstanceRef.current && (window as any).mapkit) {
        (window as any).mapkit.init({
          authorizationCallback: function (done: (doneToken: string) => void) {
            done(token);
          },
        });

        mapInstanceRef.current = new (window as any).mapkit.Map(mapRef.current);
      }
    };

    if ((window as any).mapkit) {
      initializeMap();
    }
  }, [token]);

  useEffect(() => {
    if (mapInstanceRef.current && convention) {

      // Remove existing annotations
      annotationsRef.current.forEach(annotation => {
        mapInstanceRef.current.removeAnnotation(annotation);
      });

      // Create a new annotation for the single convention
      const annotation = new (window as any).mapkit.MarkerAnnotation(
        new (window as any).mapkit.Coordinate(convention.location.latitude, convention.location.longitude)
      );

      // Add the annotation to the map
      mapInstanceRef.current.addAnnotation(annotation);
      annotationsRef.current = [annotation];

      // Center the map on the convention's location
      const region = new (window as any).mapkit.CoordinateRegion(
        new (window as any).mapkit.Coordinate(convention.location.latitude, convention.location.longitude),
        new (window as any).mapkit.CoordinateSpan(0.1, 0.1) // Adjust the span to control the zoom level
      );

      mapInstanceRef.current.region = region;
    }
  }, [convention]);

  return <div ref={mapRef} style={{ width: '100%', height: '100%' }} />;
};

export default MapLocationComponent;