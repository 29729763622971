import React, { useEffect, useRef } from 'react';
import { Convention } from '../api/ApiModels/Models';
import moment from 'moment';

interface MapKitMapProps {
  token: string;
  conventions: Convention[];
  ignoreMap: boolean;
  onBoundsChange: (bounds: { north: number; south: number; east: number; west: number }) => void;
}

const MapKitMap: React.FC<MapKitMapProps> = ({ token, conventions, ignoreMap, onBoundsChange }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<any>(null);
  const annotationsMapRef = useRef<{ [id: string]: any }>({});

  useEffect(() => {
    if (!mapInstanceRef.current && (window as any).mapkit && mapRef.current) {
      (window as any).mapkit.init({
        authorizationCallback: function (done: (doneToken: string) => void) {
          done(token);
        },
      });

      mapInstanceRef.current = new (window as any).mapkit.Map(mapRef.current);

      // Set initial region
      const initialRegion = new (window as any).mapkit.CoordinateRegion(
        new (window as any).mapkit.Coordinate(37.0902, -95.7129),
        new (window as any).mapkit.CoordinateSpan(30, 60)
      );
      mapInstanceRef.current.region = initialRegion;

      mapInstanceRef.current.addEventListener('region-change-end', () => {
        const bounds = mapInstanceRef.current.region.toBoundingRegion();
        onBoundsChange({
          north: bounds.northLatitude,
          south: bounds.southLatitude,
          east: bounds.eastLongitude,
          west: bounds.westLongitude,
        });
      });
    }
  }, [token, onBoundsChange]);

  useEffect(() => {
    if (mapInstanceRef.current) {
      const mapkit = (window as any).mapkit;

      const newAnnotationsMap: { [id: string]: any } = {};
      const annotationsToAdd: any[] = [];
      const annotationsToRemove: any[] = [];

      conventions.forEach((convention) => {
        const id = convention.id;
        if (annotationsMapRef.current[id]) {
          // Annotation exists
          newAnnotationsMap[id] = annotationsMapRef.current[id];
        } else {
          // Create an image element for the callout accessory
          const imageElement = document.createElement('img');
          imageElement.src = convention.logoUrl!; // Ensure this URL is valid
          imageElement.alt = convention.name;
          imageElement.className = 'callout-accessory-image';

          // Create new annotation with callout accessory
          const annotation = new mapkit.MarkerAnnotation(
            new mapkit.Coordinate(
              convention.location.latitude,
              convention.location.longitude
            ),
            {
              title: convention.name,
              subtitle: `${moment(convention.startDate).format('MMM DD YYYY')}`,
              callout: {
                accessory: {
                  right: {
                    element: imageElement,
                  },
                },
              },
              // // Optionally, customize the marker itself
              // glyphImage: {
              //   1: convention.logoUrl, // If you have a custom marker image
              // },
            }
          );
          annotation.conventionId = id;
          annotationsToAdd.push(annotation);
          newAnnotationsMap[id] = annotation;
        }
      });

      // Remove annotations no longer in conventions
      Object.keys(annotationsMapRef.current).forEach((id) => {
        if (!newAnnotationsMap[id]) {
          annotationsToRemove.push(annotationsMapRef.current[id]);
        }
      });

      if (annotationsToRemove.length > 0) {
        mapInstanceRef.current.removeAnnotations(annotationsToRemove);
      }
      if (annotationsToAdd.length > 0) {
        mapInstanceRef.current.addAnnotations(annotationsToAdd);
      }

      annotationsMapRef.current = newAnnotationsMap;

      if (ignoreMap) {
        fitAllConventionsOnMap(Object.values(newAnnotationsMap));
      }
    }
  }, [conventions, ignoreMap]);

  const fitAllConventionsOnMap = (annotations: any[]) => {
    if (annotations.length === 0) return;

    const coordinates = annotations.map((annotation) => annotation.coordinate);
    const region = (window as any).mapkit.CoordinateRegion.fromCoordinates(coordinates);
    mapInstanceRef.current.region = region;
  };

  return <div ref={mapRef} style={{ height: '45vh', width: '70vw' }} />;
};

export default MapKitMap;