import React, { useState, useEffect } from 'react';
import { SingleValue } from 'react-select';
import { Button, Form, Container, Row, Col, Alert } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { useAuth0 } from '@auth0/auth0-react';
import * as _ from "lodash";
import AsyncSelect from 'react-select/async';
import { fetchConventionOptions, fetchUserNameOptions } from '../../Helpers/SelectHelpers';
import QuickShootsApi from '../../api/QuickShootsApi';
import { QuickShoot } from '../../interfaces/QuickShoot';

const ReserveQuickShoot: React.FC = () => {
  const [instagramHandle, setInstagramHandle] = useState<string>('');
  const [selectedConvention, setSelectedConvention] = useState<SingleValue<{ value: string; label: string }> | null>(null);
  const [selectedUserName, setSelectedUserName] = useState<SingleValue<{ value: string; label: string }> | null>(null);
  const [quickShoot, setQuickShoot] = useState<QuickShoot | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [submissionInProgress, setSubmissionInProgress] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState<number>(0);
  const { getAccessTokenSilently } = useAuth0();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSubmissionInProgress(true);

    if (!selectedConvention) {
      setError('Convention selection is required.');
      setSubmissionInProgress(false);
      return;
    }
    try {
      const processor = new QuickShootsApi(getAccessTokenSilently);
      const code = await processor.reserveQuickShoot(instagramHandle, selectedConvention.value, selectedUserName?.value);
      setQuickShoot(code!);
      setError(null);
    } catch (err) {
      if (retryCount < 1) {
        setRetryCount(retryCount + 1);
      } else {
        setError('Failed to reserve. Please refresh the page and try again.');
      }
    }
    setSubmissionInProgress(false);
  };

  const handleRetry = () => {
    setRetryCount(0);
    setError(null);
    setQuickShoot(null);
  };

  return (
    <Container>
      <h2 className='text-center'>Quick Shoot</h2>
      <Row className="justify-content-md-center">
        <Col md={6}>
          {quickShoot ? (
            <>
              <div className="row">
                <div className='col text-center'>
                  <QRCode value={`https://cosplaycorps.com/QuickShootQR/${quickShoot.id}`} />
                </div>
              </div>
              <div className='row'>
                <div className='col text-center'>
                  Visit for your pics! <br /><span>https://cosplaycorps.com/searchQuickShoot</span>
                  <p>QuickShoot Code: {quickShoot.referenceCode}</p>
                  <p>Convention: {quickShoot.conventionName}</p>
                  {instagramHandle && <p>{instagramHandle}</p>}
                </div>
              </div>
            </>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formConvention">
                <Form.Label>Convention (required)</Form.Label>
                <AsyncSelect
                  loadOptions={(t) => fetchConventionOptions(t, false, getAccessTokenSilently)}
                  cacheOptions
                  value={selectedConvention}
                  onChange={(newValue) => setSelectedConvention(newValue)}
                  isClearable
                />
              </Form.Group>
              <Form.Group controlId="formInstagramHandle">
                <Form.Label>Instagram Handle (optional)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Instagram Handle"
                  value={instagramHandle}
                  onChange={(e) => setInstagramHandle(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formUsername" className='mb-2'>
                <Form.Label>Username (optional)</Form.Label>
                <AsyncSelect
                  loadOptions={(t) => fetchUserNameOptions(t, getAccessTokenSilently)}
                  cacheOptions
                  value={selectedUserName}
                  onChange={(newValue) => setSelectedUserName(newValue)}
                  isClearable
                />
              </Form.Group>
              {error && <Alert variant="danger">{error}</Alert>}
              {
                !submissionInProgress ?
                <Button variant="primary" type="submit">
                  Submit
                </Button> :
                <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Submitting...
                </button>
              }
              {retryCount > 0 && !quickShoot && (
                <Button variant="warning" onClick={handleRetry} className="ml-2">
                  Retry
                </Button>
              )}
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ReserveQuickShoot;