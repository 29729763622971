import React, { ReactNode } from 'react'
import DesktopNavBar from '../components/System/DesktopNavBar'
import MobileBottomNavbar from '../components/System/MobileBottomNavbar'

interface DashboardLayoutProps {
  children: ReactNode
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  return (
    <>
      <div
        className="body-content d-flex flex-column flex-lg-row overflow-x-hidden"
        style={{ minHeight: '100vh', marginBottom: '100px' }}
      >
        {/* Desktop Navigation */}
        <nav className="position-fixed z-100 top-0 vh-100">
          <DesktopNavBar />
        </nav>

        {/* Main Content */}
        <main className="flex-grow-1 container-fluid h-100 margin-left-250">
          <div className="h-100 p-lg-4 child-padding">{children}</div>
        </main>

        {/* Mobile Navigation */}
        <div className="d-lg-none position-fixed w-100 bottom-0 z-100 bg-light shadow-lg">
          <MobileBottomNavbar />
        </div>
      </div>
    </>
  )
}

export default DashboardLayout
