import React from "react";
import { Dropdown } from "react-bootstrap";
import { DashBoardThreeDotsIcon } from "../../components/common/Icons";
import AvatarGroup from "../../components/common/AvatarGroup";
import "../../components/common/AvatarGroup.css";

interface ConventionEventCardComponentProps {
  title: string;
  type?: string;
  date: string;
  image: string;
  location?: string;
  // avatars: string[]
}

//This component provides details about an event that is associated officially or unofficially with a given convention.
const ConventionEventCardComponent: React.FC<
  ConventionEventCardComponentProps
> = ({
  title,
  type,
  date,
  image,
  location,
  // avatars,
}) => {
  return (
    <div className="convention-tab-box p-3 mt-sm-4 mt-3 mx-auto mx-lg-0">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center gap-2">
          {image && (
            <img src={image} alt={title} className="carnival-img me-1" />
          )}
          {title && (
            <h4 className="ff-manrope fw-semibold mb-0 dark">{title}</h4>
          )}
        </div>
        {/* <Dropdown>
          <Dropdown.Toggle as="span" className="cursor-pointer">
            <DashBoardThreeDotsIcon />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <div className="ff-manrope fw-medium text-xs gray">
          {type && <p className="mb-0">Type: {type}</p>}
          {location && <p className="mb-0">Location: {location}</p>}
          {date && <p className="mb-0">Date: {date}</p>}
        </div>
        {/* <AvatarGroup images={avatars} /> */}
      </div>
    </div>
  );
};

export default ConventionEventCardComponent;
