import { User } from "@auth0/auth0-react";

export const GetUserId = (user: User) => {
    if(!user){
        return undefined;
    }
    return user['https://cosplaycorps.com/CosplayCorpsId'];
};

export const GetUserRoles = (user: User) =>{
    if(!user){
        return undefined;
    }
    return user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/roles']
}

export const GetUserDisplayName = (user: User) => {
    if(!user){
        return undefined;
    }
    return user['https://cosplaycorps.com/display_name'];
};

interface CosplayCorpsUser{
    Id : bigint;
    Auth0Id : string;
    FirstName : string;
    LastName : string;
    DisplayName : string;
    Role : string;
    ProfilePictureUri : string;
    EmailAddress : string | null;
}


export const GetCosplayCorpsUserFromToken = (user: User | undefined): CosplayCorpsUser | null =>  {
    if(!user){
        return null;
    }   
    var newUserObject: CosplayCorpsUser = {
        Id: user["https://cosplaycorps.com/CosplayCorpsId"],
        Auth0Id : user.sub!,
        FirstName : user.given_name!,
        LastName : user.family_name!,
        DisplayName : user["https://cosplaycorps.com/display_name"],
        Role : user["https://cosplaycorps.com/roles"][0] == "Admin" ? user["https://cosplaycorps.com/roles"][1] : user["https://cosplaycorps.com/roles"][1],
        ProfilePictureUri : user.picture!,
        EmailAddress : user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
    }
    return newUserObject;
}