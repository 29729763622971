import React from 'react';
import { useInfinitePhotos } from '../System/SWR/useInfinite';
import { MasonryGalleryComponent } from '../common/MasonryGalleryComponent';

interface GalleryComponentProps {
    columnOverrides?: any | null;
    filter?: string;
    photoShootId?: any
}

export const GalleryComponent: React.FC<GalleryComponentProps> = ({ columnOverrides, filter, photoShootId }) => {
    const { photos, isLoadingMore, isReachingEnd, setSize } = useInfinitePhotos(filter, photoShootId);
    const handleBottomReached = () => {
        if (!isReachingEnd) {
            setSize((prevSize: number) => prevSize + 1);
        }
    };

    return (
        <div>
            <MasonryGalleryComponent 
                photos={photos} 
                onBottomReached={handleBottomReached}
                columnOverrides={columnOverrides}
            />
            {isLoadingMore &&
                <div className="d-flex justify-content-center mt-4 mb-4">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>}
        </div>
    );
};
