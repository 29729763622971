import React, { useCallback, useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Container, Form, Button, ButtonGroup } from 'react-bootstrap';
import PhotoApiProcessor from '../../api/PhotoApiProcessor';
import { useAuth0 } from '@auth0/auth0-react';
import QuickShootsApi from '../../api/QuickShootsApi';
import { QuickShoot, QuickShootStatus } from '../../interfaces/QuickShoot';
import { Photograph } from '../Photographs/Models/Photograph';
import { fetchConventionOptions } from '../../Helpers/SelectHelpers';
import { GalleryComponent } from '../../components/Photos/GalleryComponent';
import QuickShootStatusComponent from '../../components/QuickShoot/QuickShootStatusComponent';
import { useParams } from 'react-router-dom';

const QuickShootSearch: React.FC = () => {
  const [convention, setConvention] = useState<{ value: string; label: string } | null>(null);
  const [referenceCode, setReferenceCode] = useState<string>('');
  const [quickShoot, setQuickShoot] = useState<QuickShoot | null>(null);
  const [photos, setPhotos] = useState<Photograph[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const { quickShootId } = useParams<{ quickShootId?: string }>();

  const fetchPhotos = useCallback(async () => {
    const quickShootsProcessor = new QuickShootsApi(getAccessTokenSilently);
    const photoApiProcessor = new PhotoApiProcessor(getAccessTokenSilently);

    try {
      if (quickShootId) {
        const tempQuickShoot = await quickShootsProcessor.retrieveQuickShootById(quickShootId);
        setQuickShoot(tempQuickShoot);
        if (tempQuickShoot) {
          const fetchedPhotos = await photoApiProcessor.getPhotosByQuickShootId(tempQuickShoot.id.toString());
          setPhotos(fetchedPhotos);
          return;
        }
      }
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  }, [quickShootId, getAccessTokenSilently]);

  useEffect(() => {
    fetchPhotos();
  }, [fetchPhotos]);

  const handleSubmit = async (e: React.FormEvent) => {
    const quickShootsProcessor = new QuickShootsApi(getAccessTokenSilently);
    const photoApiProcessor = new PhotoApiProcessor(getAccessTokenSilently);
    e.preventDefault();
    if (convention && referenceCode) {
      const tempQuickShoot = await quickShootsProcessor.retrieveQuickShoot(convention.value, referenceCode);

      if (!tempQuickShoot) {
        const fetchedPhotos = await photoApiProcessor.getPhotosByConventionIdAndReferenceCode(convention.value, referenceCode);
        setPhotos(fetchedPhotos);
        return;
      }

      setQuickShoot(tempQuickShoot);

      if (tempQuickShoot.status === QuickShootStatus.Complete) {
        const fetchedPhotos = await photoApiProcessor.getPhotosByQuickShootId(tempQuickShoot.id.toString());
        setPhotos(fetchedPhotos);
      }
    }
  };

  const batchDownload = async (e: React.FormEvent) => {
    const photoApiProcessor = new PhotoApiProcessor(getAccessTokenSilently);
    e.preventDefault();
    await photoApiProcessor.downloadQuickShootImages(
      quickShoot?.userId || photos[0].ownerId,
      quickShoot?.referenceCode || referenceCode,
      quickShoot?.conventionId || Number(convention!.value)
    );
  };

  const breakpointOverrides = {
    2200: 3,
    1480: 2,
    1000: 1
  };

  return (
    <div>
      {!quickShootId ?
      <>
      <h2>Photo Shoot Search</h2>
      <div>
        Difference between <span style={{ fontStyle: "italic", fontWeight: "bold" }}>Quick Shoots</span> and <span style={{ fontStyle: "italic", fontWeight: "bold" }}>Photo Shoots</span> on our platform is that we can allow the photographer to provide updates when they do a QuickShoot and a photo shoot you will have to just wait until images become available with no status updates.
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Convention</Form.Label>
          <AsyncCreatableSelect
            cacheOptions
            loadOptions={(t) => fetchConventionOptions(t, true, getAccessTokenSilently)}
            defaultOptions
            onChange={setConvention}
            value={convention}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Reference Code</Form.Label>
          <Form.Control
            type="text"
            value={referenceCode}
            onChange={(e) => setReferenceCode(e.target.value)}
          />
        </Form.Group>
        <br />
        <ButtonGroup>
          <Button className='btn btn-success' type="submit">Search</Button>
          {photos && photos.length !== 0 && (
            <Button className='btn btn-primary' onClick={batchDownload}>Download All</Button>
          )}
        </ButtonGroup>
      </Form>
      <br />
      </> : <div className='d-flex justify-content-between'>
        <div>
        <h2>Quickshoot at {quickShoot?.conventionName}</h2>
        <h3>{quickShoot?.referenceCode}</h3>
        </div>
        <div>
        {photos && photos.length !== 0 && (
            <Button className='btn btn-primary' onClick={batchDownload}>Download All</Button>
          )}
        </div>

      </div>}
      <QuickShootStatusComponent quickShoot={quickShoot} />
      {photos && photos.length !== 0 && (
        <div className="photo-gallery">
          <GalleryComponent columnOverrides={breakpointOverrides} photoShootId={quickShootId}/>
        </div>
      )}
      {photos.length === 0 && quickShoot === null && (
        <p>Nothing Found</p>
      )}
    </div>
  );
};

export default QuickShootSearch;
