import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

interface subscriptionOptionsProps{
  subscriptionOptions: any[]
}

const SubscriberContent = () => {
  const subcontentlist = [
    {
      img: '/images/subscribecontentimg-1.png',
      title: 'Grow the Community',
      description:
        'Connect with cosplayers and photographers from around the world.',
      groumemb: '/images/subscribecontentmember-img-1.png',
    },
    {
      img: '/images/subscribecontentimg-1.png',
      title: 'Grow the Community',
      description:
        'Connect with cosplayers and photographers from around the world.',
      groumemb: '/images/subscribecontentmember-img-1.png',
    },
    {
      img: '/images/subscribecontentimg-1.png',
      title: 'Grow the Community',
      description:
        'Connect with cosplayers and photographers from around the world.',
      groumemb: '/images/subscribecontentmember-img-1.png',
    },
  ]

  return (
    <>
      <div className="pb-5 pb-md-0">
        <div className="container container-custom bg-white px-lg-3 mb-5 mb-md-0">
          <div className="p-lg-4 bg-white profile-box-main-lg">
            <p className="common-head">Subscriber Content</p>
            <Swiper
              className="bg-white py-3 overflow-visible"
              modules={[Navigation]}
              spaceBetween={30}
              slidesPerView={1}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {subcontentlist.map((obj, index) => (
                <SwiperSlide key={index} className="shadow rounded-4 py-3">
                  <article className=" bg-white subscribecont_side_p">
                    <img
                      className="w-100"
                      src={obj.img}
                      alt="subscribecontentimg-1"
                    />
                    <h5 className="pt-3 font-semibold text-md text-dark ff-manrope">
                      {obj.title}
                    </h5>
                    <p className="pt- text-s font-normal ff-manrope">
                      {obj.description}
                    </p>
                    <img
                      className="pt-2"
                      src={obj.groumemb}
                      alt="subscribecontentmember-img-1"
                    />
                  </article>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriberContent
