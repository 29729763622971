// src/pages/SignUpWizard/index.tsx
import React, { useState, useRef, useEffect } from 'react';
import CharacterSheet from '../../components/User/signup/CharacterSheet';
import ChooseRole from '../../components/User/signup/ChooseRole';
import TermsAndConditions from '../../components/User/signup/TermsAndConditions';
import UserApiProcessor from '../../api/UserApiProcessor';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';

const SignUpWizard: React.FC = () => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<any>({});
  const sectionsRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { user, getAccessTokenSilently, logout, loginWithRedirect } = useAuth0();

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleSubmit = async () => {
    if (formData.termsAccepted) {
      nextStep();
    } else {
      alert("You must accept the terms and conditions to proceed.");
    }

    const processor = new UserApiProcessor(getAccessTokenSilently);
    const updatedFormData = {
      ...formData,
      Auth0Id: `${user?.sub}`
    };
    try {
      if (await processor.processEnrollment(updatedFormData)) {
        // Refresh access token on success -- Force them to get a new token.
        logout({ logoutParams: { returnTo: window.location.origin } });
        loginWithRedirect();
      }else{
        toast.error("There was an error. Please check the form and try again.");
      }



    } catch (error) {
      console.error("Enrollment failed", error);
    }
  };

  useEffect(() => {
    if (sectionsRefs.current[step]) {
      sectionsRefs.current[step]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [step]);

  const steps = [
    <CharacterSheet formData={formData} setFormData={setFormData} nextStep={nextStep} />,
    <ChooseRole formData={formData} setFormData={setFormData} nextStep={nextStep} />,
    // <StripeSection formData={formData} setFormData={setFormData} nextStep={nextStep} />,
    <TermsAndConditions formData={formData} setFormData={setFormData} nextStep={nextStep} />
  ];

  return (
    <div style={{ overflowY: 'auto', height: '100vh' }}>
      {steps.map((StepComponent, index) => (
        <div
          key={index}
          ref={(el) => (sectionsRefs.current[index] = el)}
          style={{ padding: '20px 0', display: index <= step ? 'block' : 'none' }}
        >
          {StepComponent}
        </div>
      ))}
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <button className='btn btn-success' onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default SignUpWizard;