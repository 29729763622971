import { QuickShootStatus } from "../interfaces/QuickShoot";

export const getStatusLabel = (status: QuickShootStatus) => {
    switch (status) {
      case QuickShootStatus.NotStarted:
        return "Not Started";
      case QuickShootStatus.EditingInProgress:
        return "Editing In Progress";
      case QuickShootStatus.Complete:
        return "Complete";
      default:
        return "";
    }
  };