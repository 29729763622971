import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserApiProcessor from '../../../api/UserApiProcessor';
import { useAuth0 } from '@auth0/auth0-react';

interface CharacterSheetProps {
    formData: {
        firstName?: string;
        lastName?: string;
        email?: string;
        userName?: string;
        twitch?: string;
        youTube?: string;
        instagram?: string;
        x?: string;
        facebook?: string;
        linkedIn?: string;
    };
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    nextStep: () => void;
}

const CharacterSheet: React.FC<CharacterSheetProps> = ({ formData, setFormData, nextStep }) => {
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isUserNameAvailable, setIsUserNameAvailable] = useState<boolean | null>(null);
    const [characterSheetFilled, setCharacterSheetFilled] = useState<boolean>(false);
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const userApiProcessor = new UserApiProcessor(getAccessTokenSilently);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev: any) => ({ ...prev, [name]: value }));
        if (name == "userName") {
            const isUserNameValid = value != null && value.trim() !== '';
            // Check username availability when the username changes
            if (isUserNameValid) {
                checkUserNameAvailability(value);
            }
        }
    };

    const checkUserNameAvailability = async (userName: string) => {
        if (userName) {
            try {
                const isUserNameAvailable = await userApiProcessor.checkUserNameAvailability(userName);
                setIsUserNameAvailable(!isUserNameAvailable);
            } catch (error) {
                console.error('Failed to check username availability:', error);
                setIsUserNameAvailable(false);
            }
        }
    };

    useEffect(() => {
        const isFirstNameValid = formData.firstName != null && formData.firstName.trim() !== '';
        const isLastNameValid = formData.lastName != null && formData.lastName.trim() !== '';
        const isUserNameValid = formData.userName != null && formData.userName.trim() !== '' && formData.userName.length > 3;

        setIsFormValid(isFirstNameValid && isLastNameValid && isUserNameValid && (isUserNameAvailable != null && isUserNameAvailable));
    }, [formData]);

    return (
        <div className="character-sheet container">
            <h2 className='text-center'>General Information</h2>
            <form>
                <div className='row'>
                    <div className='col-lg-4 col-12'>
                        <div className="form-group mb-3">
                            <label htmlFor="userName" className="form-label">Username</label>
                            <input
                                type="text"
                                className={`form-control ${isUserNameAvailable === false ? 'is-invalid' : ''}`}
                                id="userName"
                                name="userName"
                                value={formData.userName || ''}
                                onChange={handleChange}
                            />
                            {isUserNameAvailable === false && (
                                <div className="invalid-feedback">
                                    Username is already taken.
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-4 col-12'>
                        <div className="form-group mb-3">
                            <label htmlFor="firstName" className="form-label">First Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName || ''}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='col-lg-4 col-12'>
                        <div className="form-group mb-3">
                            <label htmlFor="lastName" className="form-label">Last Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName || ''}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='text-center'>
                            <h3>Streaming Platforms</h3>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mb-3">
                                <label htmlFor="lastName" className="form-label">Twitch</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="twitch"
                                    name="twitch"
                                    value={formData.twitch || ''}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mb-3">
                                <label htmlFor="lastName" className="form-label">YouTube</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="youTube"
                                    name="youTube"
                                    value={formData.youTube || ''}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='text-center'>
                            <h3>Social Media</h3>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mb-3">
                                <label htmlFor="instagram" className="form-label">Instagram</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="instagram"
                                    name="instagram"
                                    value={formData.instagram || ''}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mb-3">
                                <label htmlFor="lastName" className="form-label">LinkedIn</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="linkedIn"
                                    name="linkedIn"
                                    value={formData.linkedIn || ''}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mb-3">
                                <label htmlFor="lastName" className="form-label">Facebook</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="facebook"
                                    name="facebook"
                                    value={formData.facebook || ''}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4 col-12'>
                            <div className="form-group mb-3">
                                <label htmlFor="lastName" className="form-label">X (Formerly Twitter)</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="x"
                                    name="x"
                                    value={formData.x || ''}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button
                            type="button"
                            className="btn btn-primary"
                            hidden={characterSheetFilled}
                            onClick={() => { nextStep(); setCharacterSheetFilled(true); }}
                            disabled={!isFormValid || isUserNameAvailable === false}
                        >
                            Next
                        </button>
                    </div>
                </div>

            </form>
        </div>
    );
};

export default CharacterSheet;
