interface MetricCardComponentProps {
    backgroundColor: string;
    icon: any;
    viewColor: string;
    value: string | Number;
    description: string;
}

export const MetricCardComponent: React.FC<MetricCardComponentProps> = ({ backgroundColor, icon, viewColor, value, description }) => {
    return (
        <div style={{minWidth: '250px'}} className={`colorfull-card ${backgroundColor}`}>
            <div className="d-flex justify-content-between">
                <div className="icon-box bg-white d-flex align-items-center justify-content-center">
                    {icon}
                </div>
            </div>
            <p className={`ff-manrope fw-bold mt-3 pt-1 text-2lg lh-38 mb-0 ${viewColor}`}>
                {value.toString()}
            </p>
            <p className="ff-manrope fw-normal text-sm dark mt-2 pt-1 lh-24 mb-0">
                {description}
            </p>
        </div>
    )
}