import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import {
  ConventionIcon,
  DashboardIcon,
  GalleryIcon,
  QuickShootIcon,
  UploadIcon,
} from "../common/Icons";
import LogoutButton from "../Auth/logout";
import LoginButton from "../Auth/login";

interface SidebarProps {
  isAuthenticated: boolean;
  user: any;
}

const Sidebar: React.FC<SidebarProps> = ({ isAuthenticated, user }) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  const navLinks = [
    {
      to: "/dashboard",
      label: "My Dashboard",
      icon: (
        <DashboardIcon isActive={activePath.toLowerCase() === "/dashboard"} />
      ),
    },
    {
      to: "/Gallery",
      label: "Photo Gallery",
      icon: <GalleryIcon isActive={activePath.toLowerCase() === "/gallery"} />,
    },
    {
      to: "/upload",
      label: "Upload",
      icon: <UploadIcon isActive={activePath.toLowerCase() === "/upload"} />,
    },
    {
      to: "/adhocPhotoShoot",
      label: "Quick Shoot",
      icon: (
        <QuickShootIcon
          isActive={activePath.toLowerCase() === "/adhocphotoshoot"}
        />
      ),
    }
  ];

  if (
    isAuthenticated &&
    user &&
    user["https://cosplaycorps.com/CosplayCorpsId"] == 3
  ) {
    navLinks.push({
      to: "/conventions",
      label: "Conventions",
      icon: (
        <ConventionIcon
          isActive={activePath.toLocaleLowerCase() === "/conventions"}
        ></ConventionIcon>
      ),
    })
  }

  return (
    <Navbar className="mobile-nav d-lg-flex z-100 duration-300 flex-column border-end rounded-0 vh-100 pb-4 px-4 dashboard-list">
      <Nav className="flex-column gap-2 gap-lg-4 mt-lg-3">
        {navLinks.map(({ to, label, icon }) => (
          <Nav.Link
            key={to}
            as={NavLink}
            to={to}
            className="nav-link d-flex gap-3 align-items-center"
          >
            <button className="btn p-0">{icon}</button>
            <span>{label}</span>
          </Nav.Link>
        ))}
      </Nav>
      <div className="mt-auto text-center">
        {isAuthenticated ? <LogoutButton /> : <LoginButton />}
      </div>
    </Navbar>
  );
};

export default Sidebar;
