interface PersonCardComponentProps {
  userName: string;
  profileImg?: string;
}
const PersonCardComponent: React.FC<PersonCardComponentProps> = ({
  userName,
  profileImg,
}) => {  
  const handleNewTabNavigation = (path: string) => {
    const newTab = window.open();
    newTab!.opener = null;
    newTab!.location.href = path; // target path
  };

  return (
    <div className="pb-2">
      <button
        onClick={() => handleNewTabNavigation(`/user/${userName}`)}
        className="d-flex btn gap-3 align-items-center bg-light-blue p-2 rounded-5"
      >
        {profileImg && (
          <img
            className="avatar"
            src={profileImg}
            alt="cosplay"
          />
        )}
        <span className="d-none d-lg-flex">{userName}</span>
        {/* <DownArrowIcon /> */}
      </button>
    </div>
  );
};

export default PersonCardComponent;
