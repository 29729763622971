import React, { useState } from "react";
import { CloseIcon } from "../../components/common/Icons";
import UserApiProcessor from "../../api/UserApiProcessor";
import { useAuth0 } from "@auth0/auth0-react";
import { GetCosplayCorpsUserFromToken } from "../../Helpers/Auth0Helper";
import { toast } from 'react-toastify';

interface ProfileEditFormComponentProps {
  profileEditForm: ProfileEditForm;
  setIsEditing: (isEditing: boolean) => void;
}

export interface ProfileEditForm {
  firstName?: string;
  lastName?: string;
  twitch?: string;
  youTube?: string;
  instagram?: string;
  x?: string;
  facebook?: string;
  linkedIn?: string;
}

export const ProfileEditFormComponent: React.FC<ProfileEditFormComponentProps> = ({
  profileEditForm,
  setIsEditing,
}) => {
  const [profileData, setProfileData] = useState<ProfileEditForm>(profileEditForm);
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const userApi = new UserApiProcessor(getAccessTokenSilently);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(profileData)    // Handle form submission logic here
    
    var temp = GetCosplayCorpsUserFromToken(user);
    var successResult = await userApi.updateProfile(profileData, temp);
    if(successResult){
      toast.success("Profile Updated");
      setIsEditing(false);
    }else{
      toast.error("Profile failed to update. Please Try Again...");
    }
  };

  return (
    <form
      className="d-flex justify-content-center align-items-center px-4 position-fixed z-150 bg-black w-100 vh-100 bg-opacity-75 top-0 start-0"
      onSubmit={handleFormSubmit}
    >
      <div className="bg-white pt-5 pb-4 px-4 rounded-4 position-relative">
        <button
          onClick={() => setIsEditing(false)}
          className="position-absolute top-0 end-0 mt-2 me-2 border-0 bg-transparent"
        >
          <CloseIcon />
        </button>
        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="firstName" className="form-label text-black ff-manrope font-bold">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              className="form-control"
              value={profileData.firstName || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6">
            <label htmlFor="lastName" className="form-label text-black ff-manrope font-bold">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className="form-control"
              value={profileData.lastName || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="text-center">
            <h3>Streaming Platforms</h3>
          </div>
          <div className="col-lg-4 col-12">
            <div className="form-group mb-3">
              <label htmlFor="twitch" className="form-label">Twitch</label>
              <input
                type="text"
                className="form-control"
                id="twitch"
                name="twitch"
                value={profileData.twitch || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="form-group mb-3">
              <label htmlFor="youTube" className="form-label">YouTube</label>
              <input
                type="text"
                className="form-control"
                id="youTube"
                name="youTube"
                value={profileData.youTube || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="text-center">
            <h3>Social Media</h3>
          </div>
          <div className="col-lg-4 col-12">
            <div className="form-group mb-3">
              <label htmlFor="instagram" className="form-label">Instagram</label>
              <input
                type="text"
                className="form-control"
                id="instagram"
                name="instagram"
                value={profileData.instagram || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="form-group mb-3">
              <label htmlFor="linkedIn" className="form-label">LinkedIn</label>
              <input
                type="text"
                className="form-control"
                id="linkedIn"
                name="linkedIn"
                value={profileData.linkedIn || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="form-group mb-3">
              <label htmlFor="facebook" className="form-label">Facebook</label>
              <input
                type="text"
                className="form-control"
                id="facebook"
                name="facebook"
                value={profileData.facebook || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="form-group mb-3">
              <label htmlFor="x" className="form-label">X (Formerly Twitter)</label>
              <input
                type="text"
                className="form-control"
                id="x"
                name="x"
                value={profileData.x || ''}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <br />
        <button
          type="submit"
          className="border-0 rounded-2 px-2 py-1 ms-2 bg-purple text-white"
        >
          Save Changes
        </button>
        <button
          type="button"
          onClick={() => setIsEditing(false)}
          className="border-0 rounded-2 px-2 py-1 bg-dark-subtle btn-secondary ms-2"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};
