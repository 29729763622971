// src/pages/Photographs/PhotoUploadForm.tsx
import React, { useState, useEffect } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PhotoUploadFormProps } from './Models/PhotoUploadFormModel';
import { fetchConventionOptions, fetchCosplayerUserNameOptions, fetchPhotographerUserNameOptions, fetchTags } from '../../Helpers/SelectHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

const PhotoUploadForm: React.FC<PhotoUploadFormProps> = ({ quickshootCode, isProcessing, conventionId, form, setForm, handleSubmit }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const handleSocialMediaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setForm((prevForm) => ({ ...prevForm, socialMedia: { ...prevForm.socialMedia, [name]: checked } }));
  };

  const handleScopeReduction = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setForm((prevForm) => ({ ...prevForm, reducePublicAccess: checked}));
  };

  useEffect(() => {
    setForm((prevForm) => ({ ...prevForm, isQuickShoot: false }));

    if (conventionId) {
      setForm((prevForm) => ({ ...prevForm, convention: { label: "Attached to Quickshoot", value: `${conventionId}` } }));
    }
    if (quickshootCode) {
      setForm((prevForm) => ({ ...prevForm, groupingCode: quickshootCode }));
    }
    if (conventionId && quickshootCode) {
      setForm((prevForm) => ({ ...prevForm, photographer: { label: "Attached to Quickshoot", value: `-1` } }));
      setForm((prevForm) => ({ ...prevForm, isQuickShoot: true }));
    }

    if (!form.date) {
      setForm((prevForm) => ({ ...prevForm, date: new Date() }));
    }
  }, [form.date, setForm]);

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      When checked images uploaded will only be discoverable through associated profile pages and direct links.
    </Tooltip>
  );

  return (
    <form>
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-4 mb-3">
          <label>Description</label>
          <input
            type="text"
            className="form-control"
            value={form.description!}
            onChange={(e) => setForm((prevForm) => ({ ...prevForm, description: e.target.value }))}
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-3">
          <label>Cosplayers</label>
          <AsyncCreatableSelect
            cacheOptions
            loadOptions={(t) => fetchCosplayerUserNameOptions(t, getAccessTokenSilently)}
            defaultOptions
            isMulti
            value={form.cosplayers}
            isValidNewOption={(t) => t.length > 3}
            onChange={(newValue) => setForm((prevForm) => ({ ...prevForm, cosplayers: newValue }))}
            isClearable
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-3">
          <label>Photographer</label>
          <AsyncCreatableSelect
            cacheOptions
            loadOptions={(t) => fetchPhotographerUserNameOptions(t, getAccessTokenSilently)}
            defaultOptions
            value={form.photographer}
            isDisabled={conventionId != null && quickshootCode != null}
            isValidNewOption={(t) => t.length > 3}
            onChange={(newValue) => setForm((prevForm) => ({ ...prevForm, photographer: newValue }))}
            isClearable
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-3">
          <label>Convention</label>
          <AsyncCreatableSelect
            cacheOptions
            loadOptions={(t) => fetchConventionOptions(t, true, getAccessTokenSilently)}
            defaultOptions
            value={form.convention}
            isDisabled={conventionId != null && quickshootCode != null}
            isValidNewOption={(t) => t.length > 3}
            onChange={(newValue) => setForm((prevForm) => ({ ...prevForm, convention: newValue }))}
            isClearable
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-3">
          <label>Tags</label>
          <AsyncCreatableSelect
            cacheOptions
            loadOptions={(t) => fetchTags(t, getAccessTokenSilently)}
            defaultOptions
            isMulti
            isValidNewOption={(t) => t.length > 2}
            value={form.tags}
            onChange={(newValue) => setForm((prevForm) => ({ ...prevForm, tags: newValue }))}
            isClearable
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-3">
          <label>Grouping Code</label>
          <input
            type="text"
            className="form-control"
            disabled={quickshootCode != null || false}
            value={form.groupingCode!}
            onChange={(e) => setForm((prevForm) => ({ ...prevForm, groupingCode: e.target.value }))}
          />
        </div>
        <div className="col-md-6 col-lg-4 mb-3">
          <Form.Group>
            <Form.Label>Date Taken</Form.Label>
            <br />
            <DatePicker
              selected={form.date}
              onChange={(date: Date) => setForm((prevForm) => ({ ...prevForm, date }))}
              className="form-control"
              placeholderText="DD-MM-YYYY"
              dateFormat="dd-MM-yyyy"
              maxDate={new Date()} // Restrict future dates
            />
          </Form.Group>
        </div>
        <div className='col-md-6 col-lg-4 mb-3'>
          <div className="form-check">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <input
                className="form-check-input"
                type="checkbox"
                name="reducePublicAccess"
                checked={form.reducePublicAccess}
                onChange={handleScopeReduction}
              />
            </OverlayTrigger>
            <label className="form-check-label">Reduce Public Access</label>
          </div>
        </div>


      </div>
      {/* <div className="row justify-content-center mb-3">
        <div className="col-md-6 col-lg-4">
          <div className="form-group">
            <label>Post to Social Media</label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="facebook"
                disabled
                checked={form.socialMedia.facebook}
                onChange={handleSocialMediaChange}
              />
              <label className="form-check-label">Facebook</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="instagram"
                disabled
                checked={form.socialMedia.instagram}
                onChange={handleSocialMediaChange}
              />
              <label className="form-check-label">Instagram</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="twitter"
                disabled
                checked={form.socialMedia.twitter}
                onChange={handleSocialMediaChange}
              />
              <label className="form-check-label">Twitter</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="threads"
                disabled
                checked={form.socialMedia.threads}
                onChange={handleSocialMediaChange}
              />
              <label className="form-check-label">Threads</label>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row justify-content-center">
        <div className="col-auto">
          <button onClick={handleSubmit} type="button" disabled={isProcessing} className="btn btn-success">{isProcessing ? "Processing ..." : "Submit"}</button>
        </div>
      </div>
    </form>
  );
};

export default PhotoUploadForm;