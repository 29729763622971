import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { GalleryComponent } from '../../components/Photos/GalleryComponent';
import QuickShootsApi from '../../api/QuickShootsApi';
import { QuickShoot } from '../../interfaces/QuickShoot';
import './css/Gallery.css';

const PhotoGallery: React.FC = () => {
  const { conventionId, referenceCode, quickShootId } = useParams<{ conventionId?: string, referenceCode?: string, quickShootId?: string }>();
  const [quickShoot, setQuickShoot] = useState<QuickShoot | null>(null);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<any>({
    convention: conventionId || null,
  });
  const { getAccessTokenSilently } = useAuth0();

  const fetchQuickShoot = useCallback(async () => {
    try {
      const quickShootsProcessor = new QuickShootsApi(getAccessTokenSilently);
      if(quickShootId){
        setQuickShoot(await quickShootsProcessor.retrieveQuickShootById(quickShootId));
      }else if ((conventionId && referenceCode)) {
        setQuickShoot(await quickShootsProcessor.retrieveQuickShoot(conventionId, referenceCode));
        return;
      } 
    } catch (error) {
      console.error('Error fetching quickShoot:', error);
    }
  }, [page, filters]);

  useEffect(() => {
    fetchQuickShoot();
  }, [fetchQuickShoot]);

  const handleFilterChange = (newFilters: any) => {
    setFilters(newFilters);
    setPage(1);
  };



  return (
    <>
      <div className="container-fluid">
        <h2>{quickShoot && quickShoot.conventionName && <>{quickShoot.conventionName + " - "}</>} Photo Gallery</h2>
        {/* <FilterComponent onChange={handleFilterChange} /> */}
        <GalleryComponent photoShootId={quickShoot?.id} filter='' />
      </div>
    </>
  );
};

export default PhotoGallery;
