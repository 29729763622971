import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ConventionCard from './ConventionCard'
import moment from 'moment'
import { Convention } from '../../api/ApiModels/Models'

interface ConventionTabProps {
  conventions: Convention[] | null
}

const ConventionTab: React.FC<ConventionTabProps> = ({conventions}) => {
  return (
    conventions && conventions.length != 0 ?
    <Row>
      {conventions.map((item) => (
        <Col md={6} lg={4} key={item.id}>
          <ConventionCard
            id={item.id}
            title={item.name}
            type={"Convention"}
            date={moment(item.startDate).format("DD MMM yyyy")}
            image={item.logoUrl ?? "/images/carnival.png"}
            // avatars={item.attendeeIds}
          />
        </Col>
      ))}
    </Row> : <div className='text-center'><h3>No Events for User</h3></div>
  )
}

export default ConventionTab
