import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
interface FeatureSectionProps {
  title: string;
  description: string;
  buttonText: string;
  imageSrc: string;
  reverse?: boolean;
  buttonStyle?: React.CSSProperties;
  imageBgColor?: string;
  paragraphMaxWidth?: string;
  buttonTextColor?: string;
  customStyle?: React.CSSProperties;
  textColumnStyle?: React.CSSProperties;
}

const FeatureSection: React.FC<FeatureSectionProps> = ({
  title,
  description,
  buttonText,
  imageSrc,
  reverse = false,
  buttonStyle = {},
  imageBgColor = "#fff",
  paragraphMaxWidth = "100%",
  buttonTextColor = "#000",
  customStyle = {},
  textColumnStyle = {},
}) => {
  const [isLargeScreen, setIsLargeScreen] = React.useState(
    window.innerWidth >= 992
  );

  React.useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`feature-section custom-container mt-sm-5 pt-5 mx-auto px-3 ${
        reverse ? "flex-lg-row-reverse" : "flex-lg-row"
      }`}
      style={customStyle} id="about"
    >
      <div
        className={`row pt-xl-5 mt-3 px-3 my-xl-5 align-items-center flex-column flex-lg-row ${
          reverse ? "flex-lg-row-reverse justify-content-lg-end" : ""
        }`}
      >
        <div
          className="col-lg-6 col-12 d-flex feature-image d-block d-lg-none"
          style={{ backgroundColor: imageBgColor }}
        >
          <img
            src={imageSrc}
            alt={title}
            width={550}
            height={487}
            className="featureimg"
          />
        </div>
        <div className="col-lg-6 col-12 pt-4 pt-lg-0 d-flex flex-column align-items-center justify-content-center px-sm-3">
          <div style={isLargeScreen ? textColumnStyle : {}}>
            <button
              className="featurebtn mb-3 mb-md-4"
              style={{ color: buttonTextColor }}
            >
              {buttonText}
            </button>
            <h3 className="ff-anton font-normal text-xl dark">{title}</h3>
            <p
              className="font-normal text-md light lh-40 ff-manrope pt-lg-4"
              style={{ maxWidth: paragraphMaxWidth }}
            >
              {description}
            </p>
            <a href="hKFo2SA4QXgzMFM3SFZEMFZncU9YNUQ1eFRneHBwZDI1SXM0b6Fur3VuaXZlcnNhbC1sb2dpbqN0aWTZIFMyRzJvWmdlalFZZ1RvMjlaSUVVZlFEbUxPUW54TW1Do2NpZNkgajFxVDZaTUFoTXdCS0FWcW9pM2ZPaDRLSWh3a1FUM24">
              <Button variant="outline-primary" className="featurebtn2 mt-lg-3">
                JOIN NOW
              </Button>
            </a>
          </div>
        </div>
        <div
          className="col-lg-6 col-12 mt-4 mt-lg-0 feature-image d-none d-lg-block"
          style={{ backgroundColor: imageBgColor }}
        >
          <img
            src={imageSrc}
            alt={title}
            width={550}
            height={487}
            className="featureimg"
          />
        </div>
      </div>
    </div>
  );
};

FeatureSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  buttonStyle: PropTypes.object,
  imageBgColor: PropTypes.string,
  paragraphMaxWidth: PropTypes.string,
  buttonTextColor: PropTypes.string,
  customStyle: PropTypes.object,
  textColumnStyle: PropTypes.object,
};

export default FeatureSection;
