import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { getStateOrTerritoryByKey } from "../../components/System/StateSelect";
import MapLocationComponent from "../../components/MapLocationComponent";
import WeatherChart from "../../components/common/WeatherComponent";
import {
  BookingRequestIcon,
  CosplayerIcon,
  PhotographerIcon,
} from "../../components/common/Icons";
import ConventionEventCardComponent from "./ConventionEventCardComponent";
import moment from "moment";
import "./css/conventionDetails.css";
import { Convention, ConventionDetailData } from "../../api/ApiModels/Models";
import { MetricCardComponent } from "../../components/common/MetricCardComponent";
import ConventionApi from "../../api/ConventionApi";
import { GetCosplayCorpsUserFromToken } from "../../Helpers/Auth0Helper";
import ConventionModal from "../../components/Convention/ConventionAttendanceModal";

const ConventionDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate(); // Hook for navigation

  const [convention, setConvention] = useState<Convention | null>(null);
  const [conventionDetails, setConventionDetails] =
    useState<ConventionDetailData | null>(null);

  const [attendingConvention, setAttendingConvention] = useState<boolean>(
    conventionDetails?.isAttending ?? false
  );

  const mapKitToken = process.env.REACT_APP_APPLE_MAPKIT_TOKEN!;
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const conventionApi = new ConventionApi(getAccessTokenSilently);

  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);

  const handleModalSubmit = async (attending: boolean, badgePrice?: number) => {
    if (convention) {
      if (
        await conventionApi.attendConvention(
          convention.id,
          badgePrice ?? 0,
          attending
        )
      ) {
        setAttendingConvention(!attendingConvention);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      var data = await conventionApi.fetchConventionDetails(id);
      if (data !== null && data !== undefined) {
        setConventionDetails(data);
        setConvention(data.convention!);
        setAttendingConvention(data.isAttending);
      }
    };
    fetchData();
  }, [id]);

  return convention ? (
    <div className="container-fluid">
      <ConventionModal
        show={showModal}
        handleClose={handleModalClose}
        handleSubmit={handleModalSubmit}
        conventionName={convention.name}
      />
      <div className="alert alert-danger" role="alert">
        If you have come across this page. This is a work in progress. The data
        here is likely to be inaccurate.
      </div>
      <div className="">
        <div className="row convention-detail-box">
          <div className="col-3">
            <MapLocationComponent
              ignoreMap={false}
              token={mapKitToken}
              convention={conventionDetails?.convention!}
            />
          </div>
          <div className="col-9">
            <div className="d-flex justify-content-between align-items-start">
              <ConventionData convention={convention} />
              <button
                className="btn btn-primary"
                onClick={() => setShowModal(true)}
              >
                {attendingConvention ? "Mark Not Attending" : "Mark Attending"}{" "}
                Convention
              </button>
            </div>{" "}
            <h3>Convention Forecast</h3>
            <WeatherChart
              latitude={conventionDetails?.convention?.location?.latitude!}
              longitude={conventionDetails?.convention?.location?.longitude!}
              startDate={conventionDetails?.convention?.startDate!}
              endDate={conventionDetails?.convention?.endDate!}
            />
          </div>
        </div>
        <div>
          <h2>Convention Stats</h2>
          <div
            className="d-flex flex-wrap justify-content-start"
            style={{ gap: "20px" }}
          >
            <div
              className="flex-grow-1"
              style={{ flexBasis: "calc(25% - 20px)" }}
            >
              <MetricCardComponent
                value={conventionDetails?.totalAttendeeCount ?? 0}
                icon={<PhotographerIcon />}
                viewColor="light-green"
                description="Total Visitors"
                backgroundColor="bg-aero-blue"
              />
            </div>
            <div
              className="flex-grow-1"
              style={{ flexBasis: "calc(25% - 20px)" }}
            >
              <MetricCardComponent
                value={convention.attendeeIds.length}
                icon={<CosplayerIcon />}
                viewColor="light-green"
                description="Photographers"
                backgroundColor="bg-aero-blue"
              />
            </div>
            <div
              className="flex-grow-1"
              style={{ flexBasis: "calc(25% - 20px)" }}
            >
              <MetricCardComponent
                value={
                  conventionDetails?.maxBadgePrice ==
                  conventionDetails?.minBadgePrice
                    ? `$${conventionDetails?.minBadgePrice}`
                    : `$${conventionDetails?.minBadgePrice} - $${conventionDetails?.maxBadgePrice}`
                }
                icon={<BookingRequestIcon />}
                viewColor="light-green"
                description="Badge Price Range"
                backgroundColor="bg-aero-blue"
              />
            </div>
            <div
              className="flex-grow-1"
              style={{ flexBasis: "calc(25% - 20px)" }}
            >
              <MetricCardComponent
                value={
                  conventionDetails?.anticipatedAttendeeCount === -1
                    ? "Unable to Estimate"
                    : `${conventionDetails?.anticipatedAttendeeCount}`
                }
                icon={<BookingRequestIcon />}
                viewColor="light-green"
                description="Expected Attendance Count"
                backgroundColor="bg-aero-blue"
              />
            </div>
          </div>
        </div>
        <div className="mt-2">
          <h2>After Parties</h2>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
            {conventionDetails?.associatedEvents &&
              conventionDetails?.associatedEvents.map((event, index) => (
                <ConventionEventCardComponent
                  image=""
                  title={event.eventName}
                  date={moment(event.startTime).format("ddd, DD MMM")}
                  location={event.venue}
                />
              ))}
          </div>
        </div>
        <div className="mt-2">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Available Photographers</h2>
            {isAuthenticated &&
              user &&
              conventionDetails?.photographerSessions?.findIndex(
                (x) =>
                  x.photographerId === GetCosplayCorpsUserFromToken(user)?.Id
              ) !== -1 && ( // If its -1 then they havent established a photoshoot
                <button
                  onClick={() => {
                    navigate(`/createbooking/${convention.id}`);
                  }}
                  className="btn btn-primary"
                >
                  Add Schedule
                </button>
              )}
          </div>
          {conventionDetails?.photographerSessions &&
            conventionDetails?.photographerSessions.length == 0 && (
              <>No Bookings are posted for this convention</>
            )}
          <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
            {conventionDetails?.photographerSessions &&
              conventionDetails?.photographerSessions.map((photog, index) => (
                <div className="card border-0" style={{ width: "18rem" }}>
                  <img
                    src="https://cosplaycorps.com/images/men-on-mountain-2.png"
                    className="card-img-top"
                    alt="photogProfilePic"
                  />
                  <div className="card-body">
                    <h5 className="card-title">{photog.photogDisplayName}</h5>
                    {/* <p className="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p> */}
                  </div>
                  <div className="card-body d-flex justify-content-between">
                    <div>Starting at ${photog.minPrice}</div>
                    <button
                      onClick={() => {
                        console.log(
                          "Nav to " +
                            photog.photogDisplayName +
                            "with id :" +
                            photog.photographerId
                        );
                      }}
                      className="btn btn-sm btn-success"
                    >
                      Book Me
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>Loading...</>
  );
};

interface conProps {
  convention: Convention;
}

const ConventionData: React.FC<conProps> = ({ convention }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={convention.logoUrl}
          alt="Convention Logo"
          style={{
            borderRadius: "50%",
            marginRight: "16px",
            height: "100px",
          }}
        />
        <div>
          <h1 style={{ marginBottom: "8px" }}>{convention.name}</h1>
          <p>
            {convention.location.address},{" "}
            {getStateOrTerritoryByKey(convention.location.state!)}
          </p>
        </div>
      </div>
    </>
  );
};

export default ConventionDetails;
