import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./css/MyProfile.css"; // Ensure you have the correct path
import MyImages from "../../components/MyProfile/MyImages";
import MySubscriberContent from "../../components/MyProfile/MySubscribeContent";
import SubscriberContent from "../../components/MyProfile/SubscriberContent";
import { EditIcon, FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon } from "../../components/common/Icons";
import { useParams } from "react-router-dom";
import { GetUserDisplayName } from "../../Helpers/Auth0Helper";
import { ProfileData, VisitorData } from "../../interfaces/Profile";
import UserApiProcessor from "../../api/UserApiProcessor";
import ProfilePlans from "../../components/MyProfile/ProfilePlans";
import { ProfileEditForm, ProfileEditFormComponent } from "./ProfileEditFormComponent";
import { MasonryGalleryComponent } from "../../components/common/MasonryGalleryComponent";

const Profile: React.FC = () => {
  const { username } = useParams(); // Extract the username from the URL

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const userApiProcessor = new UserApiProcessor(getAccessTokenSilently);

  const [vistorData, setVisitorData] = useState<VisitorData>();
  const [profileData, setProfileData] = useState<ProfileData>();
  const [profileEditForm, setProfileEditForm] = useState<ProfileEditForm>();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (isAuthenticated && user) {
        var profileData = await userApiProcessor.fetchUserProfile(username ?? GetUserDisplayName(user));
        setProfileData(profileData!);
        setProfileEditForm({
          firstName: profileData?.user.firstName,
          lastName: profileData?.user.lastName,
          twitch: profileData?.socialMedia.twitch?.username,
          youTube: profileData?.socialMedia.youTube?.username,
          instagram: profileData?.socialMedia.instagram?.username,
          x: profileData?.socialMedia.x?.username,
          facebook: profileData?.socialMedia.facebook?.username,
          linkedIn: profileData?.socialMedia.linkedIn?.username,
        })
      }
    };
    fetchProfileData();
  }, [isAuthenticated, user, username]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfileData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const convertRoleIdToString = (id: any) => {
    if (id === 3 || id === "3") {
      return "Photographer";
    }
    if (id === 2 || id === "2") {
      return "Cosplayer";
    }
    return "General";
  }

  const handleSubscribeClick = () => {
  };

  if (!isAuthenticated) {
    return <div>Please log in to view your profile.</div>;
  }

  return (!profileData ? <>Loading...</> :
    <div className="position-relative">
      <div className="w-100 container container-custom mt-4">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="profile-box box-shadow-common position-relative">
              <div className="bg-gradient-profile"></div>
              {isEditing ? (
                <ProfileEditFormComponent setIsEditing={setIsEditing} profileEditForm={profileEditForm!} />
              ) : (
                <div className="px-4 ">
                  <img
                    alt="profile"
                    src={profileData.user.profilePictureUri ?? user?.picture}
                    className="profile-picture translate-middle-y img-fluid rounded-circle mb-3"
                  />
                  <div className="profile-info d-flex justify-content-between align-items-center position-absolute w-100 bottom-0 pb-3">
                    <div className="">
                      <p className="ff-manrope text-capitalize text-lg">
                        {profileData.user.displayName}
                      </p>
                      <p className="pink ff-manrope">{convertRoleIdToString(profileData.user.role)}</p>
                    </div>
                    <div className="d-flex gap-2 align-items-center pe-5">
                      {profileData.socialMedia.facebook &&
                        <a
                          className="social-icon"
                          target="blank"
                          rel="noreferrer"
                          href={profileData.socialMedia.facebook.profileUrl}
                        >
                          <FacebookIcon />
                        </a>}
                      {profileData.socialMedia.linkedIn &&
                        <a
                          className="social-icon"
                          target="blank"
                          rel="noreferrer"
                          href={profileData.socialMedia.linkedIn.profileUrl}
                        >
                          <LinkedInIcon />
                        </a>}
                      {profileData.socialMedia.x &&
                        <a
                          className="social-icon"
                          target="blank"
                          rel="noreferrer"
                          href={profileData.socialMedia.x.profileUrl}
                        >
                          <TwitterIcon />
                        </a>}
                      {profileData.socialMedia.instagram &&
                        <a
                          className="social-icon"
                          target="blank"
                          rel="noreferrer"
                          href={profileData.socialMedia.instagram.profileUrl}
                        >
                          <InstagramIcon />
                        </a>}
                    </div>
                  </div>
                  {isAuthenticated && user && user['https://cosplaycorps.com/CosplayCorpsId'] === profileData.user.id &&
                    <button
                      className=" border-0 bg-transparent position-absolute top-50 translate-middle-y end-0 pb-4 pe-4"
                      onClick={() => setIsEditing(true)}
                    >
                      <EditIcon />
                    </button>}
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-4 mt-lg-0 mt-4 h-100">
            <div className="bg-white box-shadow-common rounded-4 p-4">
              <div className="d-flex flex-column gap-3">
                {!profileData.socialMedia.youTube && !profileData.socialMedia.twitch &&
                  <div className="text-center">No Streaming Profiles</div>
                }

                {profileData.socialMedia.youTube &&
                  (profileData.socialMedia.youTube ?
                    <a
                      href={"https://www.youtube.com/@" + profileData.socialMedia.youTube.username}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="w-100 object-fit-cover rounded-4"
                        height={140}
                        src="/images/youtube.png"
                        alt="youtube"
                      />
                    </a> : <img
                      className="w-100 object-fit-cover rounded-4"
                      height={140}
                      src="/images/youtube.png"
                      alt="youtube"
                    />
                  )}
                {profileData.socialMedia.twitch &&
                  (profileData.socialMedia.twitch ?
                    <a
                      href={"https://www.twitch.tv/" + profileData.socialMedia.twitch.username}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="w-100 object-fit-cover rounded-4"
                        height={140}
                        src="/images/twitch.png"
                        alt="twitch"
                      />
                    </a> : <img
                      className="w-100 object-fit-cover rounded-4"
                      height={140}
                      src="/images/twitch.png"
                      alt="twitch"
                    />
                  )}
              </div >
            </div>
          </div>
        </div>
      </div>

      {profileData.subscriptionOptions && <ProfilePlans profilePlans={profileData.subscriptionOptions} subscribeClick={handleSubscribeClick} />}

      {/* Profile Owner has Subscription Data. User is the profile Owner. */}
      {profileData.subscriptionOptions
        && isAuthenticated
        && user
        && user['https://cosplaycorps.com/CosplayCorpsId'] === profileData.user.id
        && <MySubscriberContent handleFreeTrialClick={handleSubscribeClick} />}

      {/* Profile Owner has Subscription Data. User also is subscribed to said data. */}
      {profileData.subscriptionOptions && vistorData?.isSubscribed && <SubscriberContent />}

      <MyImages images={profileData.trendingPhotographs} showBottomText={false} headingText="My Images" />

      <div className="container container-custom  my-4 mx-auto ">
        <div className='profile-box-main-lg p-lg-4'>
          <h4 className="mb-4 common-head">My Photo Gallery</h4>
          {profileData.trendingPhotographs && <MasonryGalleryComponent photos={profileData.trendingPhotographs} />}
        </div>
      </div>
    </div>
  );
};

export default Profile;
