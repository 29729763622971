import React, { useState, useEffect } from "react";
import moment from "moment";
import ConventionApi from "../../api/ConventionApi";
import { useAuth0 } from "@auth0/auth0-react";
import { Convention, PhotoBookingSchedule, TimeSlot } from "../../api/ApiModels/Models";
import { useParams } from "react-router-dom";

interface CreatePhotoShootBookingProps {
  startDate: string;
  endDate: string;
}

export const CreatePhotoShootBooking: React.FC<CreatePhotoShootBookingProps> = ({
  startDate = "2024-10-01",
  endDate = "2024-10-03", // Default start and end dates
}) => {
  const { conventionId } = useParams();
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const conventionApi = new ConventionApi(getAccessTokenSilently);
  const [convention, setConvention] = useState<Convention | null>(null);

  // State for the form inputs
  const [timeDuration, setTimeDuration] = useState<number>(30); // Default to 30 minutes
  const [buffer, setBuffer] = useState<number>(0); // Default to 0 minutes
  const [startTime, setStartTime] = useState<string>("10:00"); // Default to 10 AM
  const [endTime, setEndTime] = useState<string>("19:00"); // Default to 7 PM
  const [selectedSlots, setSelectedSlots] = useState<TimeSlot[]>([]); // To store all selected time slots

  // State for prices
  const [prices, setPrices] = useState({
    customOwnership: "",
    noOwnership: "",
    partialOwnership: "",
    fullOwnership: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      let con = await conventionApi.fetchConvention(conventionId);
      if (con) {
        setConvention(con);
      }
    };
    if (conventionId && !convention) {
      fetchData();
    }

    // Precompute all slots for all dates and set them as selected by default
    const prepopulateSlots = () => {
      let allSlots: TimeSlot[] = [];
      dates.forEach((date) => {
        const slotsForDate = calculateTimeSlots(date);
        allSlots = [...allSlots, ...slotsForDate];
      });
      setSelectedSlots(allSlots); // Set all slots as selected by default
    };

    prepopulateSlots();
  }, [conventionId, timeDuration, buffer, startTime, endTime]); // Recalculate if inputs change

  // Helper function to calculate time slots
  const calculateTimeSlots = (day: string): TimeSlot[] => {
    const slots: TimeSlot[] = [];
    let start = moment(`${day} ${startTime}`, "YYYY-MM-DD HH:mm");
    const end = moment(`${day} ${endTime}`, "YYYY-MM-DD HH:mm");

    while (start < end) {
      const slotStart = moment(start); // Make a copy of the start moment
      const slotEnd = moment(start).add(timeDuration, "minutes"); // Add duration to the slot start
  
      if (slotEnd <= end) {
        slots.push({
          startTime: slotStart.format("YYYY-MM-DD HH:mm:ss"),
          endTime: slotEnd.format("YYYY-MM-DD HH:mm:ss"),
        });
      }
  
      // Move the start time forward by duration + buffer
      start = moment(slotEnd).add(buffer, "minutes");
    }
    return slots;
  };

  // Generate dates between the start and end date
  const getDatesInRange = (start: string, end: string) => {
    const startMoment = moment(start, "YYYY-MM-DD");
    const endMoment = moment(end, "YYYY-MM-DD");
    const dates = [];

    while (startMoment <= endMoment) {
      dates.push(startMoment.format("YYYY-MM-DD"));
      startMoment.add(1, "day");
    }

    return dates;
  };

  const dates = getDatesInRange(startDate, endDate);

  // Handle slot selection to toggle in state
  const handleSlotClick = (slot: TimeSlot) => {
    setSelectedSlots((prevSlots) => {
      const slotExists = prevSlots.some(
        (existingSlot) =>
          existingSlot.startTime === slot.startTime &&
          existingSlot.endTime === slot.endTime
      );

      return slotExists
        ? prevSlots.filter(
            (existingSlot) =>
              existingSlot.startTime !== slot.startTime ||
              existingSlot.endTime !== slot.endTime
          )
        : [...prevSlots, slot];
    });
  };

  // Handle price input change
  const handlePriceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setPrices((prevPrices) => ({
      ...prevPrices,
      [type]: event.target.value,
    }));
  };

  // Submit handler to log the JSON object
  const handleSubmit = async () => {
    const data: PhotoBookingSchedule = {
      prices,
      availableSlots: selectedSlots, // Now all selected slots are in a single list
      conventionId: conventionId ?? "0",
    };

    await conventionApi.createPhotoshootSchedule(data);
  };

  return (
    <div>
      {/* Input Section */}
      <div>
        <h2>Schedule Setup for {convention?.name}</h2>
        <p>
          Welcome to the Schedule Configurator. This process is here to
          facilitate creating a schedule where cosplayers can book photoshoots
          with you.
        </p>
        <div>
          <label>Time Duration per Shoot:</label>
          <select
            value={timeDuration}
            onChange={(e) => setTimeDuration(parseInt(e.target.value))}
          >
            <option value={15}>15 minutes</option>
            <option value={30}>30 minutes</option>
            <option value={60}>1 hour</option>
          </select>
        </div>
        <div>
          <label>Buffer Time (minutes):</label>
          <input
            type="number"
            min="0"
            max="60"
            value={buffer}
            onChange={(e) => setBuffer(parseInt(e.target.value))}
          />
        </div>
        <div>
          <label>Start Time:</label>
          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>
        <div>
          <label>End Time:</label>
          <input
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
        <br />

        <h3>Pricing Options</h3>
        <div className="row">
          <div className="col-2">
            <label className="form-label">Custom Ownership</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="number"
                className="form-control"
                aria-label="Custom Ownership"
                value={prices.customOwnership}
                onChange={(e) => handlePriceChange(e, "customOwnership")}
              />
            </div>
          </div>
          <div className="col-2">
            <label className="form-label">No Ownership</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="number"
                className="form-control"
                aria-label="No Ownership"
                value={prices.noOwnership}
                onChange={(e) => handlePriceChange(e, "noOwnership")}
              />
            </div>
          </div>
          <div className="col-2">
            <label className="form-label">Partial Ownership</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="number"
                className="form-control"
                aria-label="Partial Ownership"
                value={prices.partialOwnership}
                onChange={(e) => handlePriceChange(e, "partialOwnership")}
              />
            </div>
          </div>
          <div className="col-2">
            <label className="form-label">Full Ownership</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="number"
                className="form-control"
                aria-label="Full Ownership"
                value={prices.fullOwnership}
                onChange={(e) => handlePriceChange(e, "fullOwnership")}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <button type="button" onClick={handleSubmit}>
        Submit
      </button>

      {/* Time Slots Section */}
      <div>
        <h2>Time Slots</h2>
        <div>
          {dates.map((date) => (
            <div key={date}>
              <h3>{moment(date).format("DD MMM ddd")}</h3>
              <div className="d-flex flex-wrap">
                {calculateTimeSlots(date).map((slot, index) => {
                  const isSelected = selectedSlots.some(
                    (selectedSlot) =>
                      selectedSlot.startTime === slot.startTime &&
                      selectedSlot.endTime === slot.endTime
                  );
                  return (
                    <button
                      key={index}
                      onClick={() => handleSlotClick(slot)}
                      style={{
                        marginRight: "15px",
                        marginBottom: "10px",
                        backgroundColor: isSelected ? "#f0f0f0" : "#A9A9A9",
                        color: isSelected ? "#000" : "#fff",
                        textDecoration: !isSelected ? 'line-through' : ''
                      }}
                      className="btn gap-3 align-items-center p-2 rounded-5"
                    >
                      {moment(slot.startTime).format("HH:mm")} -{" "}
                      {moment(slot.endTime).format("HH:mm")}
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};