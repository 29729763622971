import { QuickShoot } from "../interfaces/QuickShoot";
import { DashboardData } from "../pages/User/MyDashboard";
import { BaseApi } from "./Base/BaseApi";

export interface AdhocPhotoshoot {
  id: string;
  name: string;
  date: string;
}

export interface PerformanceMetrics {
  conventionsAttended: number;
  subscriptionsGained: number;
  totalPhotosUploaded: number;
  totalPhotosTagged: number;
  photoViews: number;
}

export interface UpcomingEvent {
  id: string;
  name: string;
  date: string;
  type: "convention" | "photoshoot";
}

export interface DashboardModel {
  upcomingEvents: Array<UpcomingEvent>;
  stats: PerformanceMetrics;
  photoshoots: Array<AdhocPhotoshoot>;
}

class DashboardApiProcessor extends BaseApi {

  async fetchAdhocPhotoshoots(): Promise<QuickShoot[]> {
    let result = await this.makeRequest<QuickShoot[]>(
      `/Profile/Dashboard/QuickShoots`
    );
    return result ?? [];
  }

  async deleteAdhocPhotoshoots(quickShootId: string): Promise<QuickShoot[]> {
    let result = await this.makeRequest<QuickShoot[]>(
      `/Profile/Dashboard/QuickShoots/${quickShootId}`,
      "DELETE"
    );
    return result ?? [];
  }

  async fetchUserStats(): Promise<PerformanceMetrics | null> {
    return await this.makeRequest<PerformanceMetrics>(`/Profile/userStats`);
  }

  async fetchUpcomingEvents(): Promise<UpcomingEvent[]> {
    return (
      (await this.makeRequest<UpcomingEvent[]>(`/Profile/upcomingEvents`)) ?? []
    );
  }

  async fetchDashboardData(): Promise<DashboardData | null> {
    return await this.makeRequest<DashboardData>(`/Users/Dashboard`, "POST");
  }
}

export default DashboardApiProcessor;
