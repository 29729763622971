import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  BookingRequestIcon,
  NewsViewIcon,
  TimerIcon,
  UpcomingEventsIcon,
} from "../../components/common/Icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import MainChart from "./MainChart";
import { DashboardData } from "../User/MyDashboard";
import { MetricCardComponent } from "../../components/common/MetricCardComponent";
import { SocialMediaIcon } from "../../components/common/SocialMediaIcon";
import moment from "moment";
import "./css/dashboard.css";

interface DashboardDataProps {
  dashboardData: DashboardData | null
}

const DashBoardChart: React.FC<DashboardDataProps> = ({ dashboardData }) => {
  const lastUploadDate = moment(dashboardData?.lastUploadDate);

  return dashboardData ?
    <div>
      <div className="container container-custom">
        <Row className="d-none d-xl-flex justify-content-between">
          <Col className="col-4">
            <MetricCardComponent value={dashboardData.metrics.totalNewViews} icon={<NewsViewIcon />} viewColor="blue" description="Total New Views" backgroundColor={"bg-azureish-white"} />
          </Col>
          <Col className="col-4">
            <MetricCardComponent value={dashboardData.metrics.upcomingEvents} icon={<UpcomingEventsIcon />} viewColor="orange" description="Total Upcoming Events" backgroundColor={"bg-antique-white"} />
          </Col>
          <Col className="col-4">
            <MetricCardComponent value={dashboardData.metrics.bookingRequests} icon={<BookingRequestIcon />} viewColor="light-green" description="Total Booking Request" backgroundColor={"bg-aero-blue"} />
          </Col>
        </Row>
      </div>
      <div className="d-xl-none">
        <Swiper
          modules={[Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop={true}
          spaceBetween={12}
          breakpoints={{
            320: {
              slidesPerView: 1.2,
            },
            480: {
              slidesPerView: 1.5,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2.5,
            },
            992: {
              slidesPerView: 2.7,
            },
          }}
        >
          <SwiperSlide>
            <MetricCardComponent value={dashboardData.metrics.totalNewViews} icon={<NewsViewIcon />} viewColor="blue" description="Total New Views" backgroundColor={"bg-azureish-white"} />
          </SwiperSlide>
          <SwiperSlide>
            <MetricCardComponent value={dashboardData.metrics.upcomingEvents} icon={<UpcomingEventsIcon />} viewColor="orange" description="Total Upcoming Events" backgroundColor={"bg-antique-white"} />
          </SwiperSlide>
          <SwiperSlide>
            <MetricCardComponent value={dashboardData.metrics.bookingRequests} icon={<BookingRequestIcon />} viewColor="light-green" description="Total Booking Request" backgroundColor={"bg-aero-blue"} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="container container-custom">
        <Row className="mt-4 pt-2">
          <Col xl={8} className=" px-0 px-lg-3">
            <MainChart dataValues={dashboardData.metrics.lastSevenDaysViews} />
          </Col>
          <Col
            xl={4}
            className="d-flex px-0 px-lg-3 flex-xl-column flex-md-row flex-column gap-3 mt-4 mt-xl-0"
          >
            <div className="last-time-box w-100">
              <p className="ff-manrope fw-normal text-s dark mb-0 pb-2">
                Last Upload Time
              </p>
              <div className="timer-svg d-flex align-items-center justify-content-center mx-auto mt-4">
                <TimerIcon />
              </div>
              <p className="ff-manrope fw-bold text-lg dark text-end mb-0 pt-4">
                { lastUploadDate.year() == 0 ? <>No Upload</> : lastUploadDate.format("DD MMM YYYY | hh:mm A") }
              </p>
            </div>
            <div className="engagement-box w-100">
              <div className="d-flex align-items-center justify-content-between">
                <p className="ff-manrope fw-normal text-s dark mb-0 pb-2">
                  Engagement Platform
                </p>
                {/* <button disabled className="ff-manrope fw-normal text-s mb-0 addnew">
                  ADD NEW
                </button> */}
              </div>
              <div className="d-flex align-items-center gap-3 mt-3">
                {dashboardData.socialMedia.facebook && (
                  <SocialMediaIcon socialMediaType={dashboardData.socialMedia.facebook.platform} userName={dashboardData.socialMedia.facebook.username} />
                )}
                {dashboardData.socialMedia.twitch && (
                  <SocialMediaIcon socialMediaType={dashboardData.socialMedia.twitch.platform} userName={dashboardData.socialMedia.twitch.username} />
                )}
                {dashboardData.socialMedia.linkedIn && (
                  <SocialMediaIcon socialMediaType={dashboardData.socialMedia.linkedIn.platform} userName={dashboardData.socialMedia.linkedIn.username} />
                )}
                {dashboardData.socialMedia.youTube && (
                  <SocialMediaIcon socialMediaType={dashboardData.socialMedia.youTube.platform} userName={dashboardData.socialMedia.youTube.username} />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div> : <>Loading Data...</>
};

export default DashBoardChart;
