import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import * as _ from "lodash";
import SuggestionApiProcessor from "../api/SuggestionApi";

export const fetchConventionOptions = (inputValue: string, includePast: boolean, getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>) =>
    new Promise<{ value: string; label: string }[]>(async (resolve) => {
        const processor = new SuggestionApiProcessor(getAccessToken);
        if(!inputValue){
            return;
        }
        try {
            var conventions = await processor.fetchConventionNames(inputValue, includePast);
            conventions = _.filter(conventions, (con: any) => _.includes(con.label.toLowerCase(), inputValue.toLowerCase()));
            var result = _.map(conventions, (convention: any) => ({
                value: `${convention.value}`,
                label: convention.label,
            }));
            resolve(result)
        } catch (err) {
            console.error('Failed to fetch conventions:', err);
        }
    });

export const fetchCosplayerUserNameOptions = (inputValue: string, getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>) =>
    new Promise<{ value: string; label: string }[]>(async (resolve) => {
        const processor = new SuggestionApiProcessor(getAccessToken);
        if(!inputValue){
            return;
        }
        try {
            var usernames = await processor.fetchCosplayerUserNames(inputValue);
            usernames = _.filter(usernames, (username: any) => _.includes(username.value.toLowerCase(), inputValue.toLowerCase()));
            var result = _.map(usernames, (username: any) => ({
                value: username.value,
                label: username.label,
            }));
            resolve(result);
        } catch (err) {
            console.error('Failed to fetch usernames:', err);
        }
    });
    export const fetchPhotographerUserNameOptions = (inputValue: string, getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>) =>
        new Promise<{ value: string; label: string }[]>(async (resolve) => {
            const processor = new SuggestionApiProcessor(getAccessToken);
            if(!inputValue){
                return;
            }
            try {
                var usernames = await processor.fetchPhotographerUserNames(inputValue);
                usernames = _.filter(usernames, (username: any) => _.includes(username.value.toLowerCase(), inputValue.toLowerCase()));
                var result = _.map(usernames, (username: any) => ({
                    value: username.value,
                    label: username.label,
                }));
                resolve(result);
            } catch (err) {
                console.error('Failed to fetch usernames:', err);
            }
        });


        export const fetchUserNameOptions = (inputValue: string, getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>) =>
            new Promise<{ value: string; label: string }[]>(async (resolve) => {
                const processor = new SuggestionApiProcessor(getAccessToken);
                if(!inputValue){
                    return;
                }
                try {
                    var usernames = await processor.fetchCosplayerUserNames(inputValue);
                    usernames = _.filter(usernames, (username: any) => _.includes(username.value.toLowerCase(), inputValue.toLowerCase()));
                    var result = _.map(usernames, (username: any) => ({
                        value: username.value,
                        label: username.label,
                    }));
                    resolve(result);
                } catch (err) {
                    console.error('Failed to fetch usernames:', err);
                }
            });

        export const fetchTags = (inputValue: string, getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>) =>
            new Promise<{ value: string; label: string }[]>(async (resolve) => {
                const processor = new SuggestionApiProcessor(getAccessToken);
                if(!inputValue){
                    return;
                }
                try {
                    var tags = await processor.fetchTagNames(inputValue);
                    tags = _.filter(tags, (tag: any) => _.includes(tag.label.toLowerCase(), inputValue.toLowerCase()));
                    var result = _.map(tags, (tag: any) => ({
                        value: tag.value,
                        label: tag.value,
                    }));
                    resolve(result);
                } catch (err) {
                    console.error('Failed to fetch usernames:', err);
                }
            });