import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Swiper as SwiperType } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation'; 
import { ContentCreatorSubscriptionOption } from '../../interfaces/Profile';

const ProfilePlans: React.FC<{ subscribeClick: () => void, profilePlans: ContentCreatorSubscriptionOption[] }> = ({ subscribeClick, profilePlans }) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [isPrevButtonEnabled, setIsPrevButtonEnabled] = useState(true);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);

  const handlePrevClick = () => {
    setIsPrevButtonEnabled(false);
    setIsNextButtonEnabled(true);
    swiperRef.current?.slidePrev();
  };

  const handleNextClick = () => {
    setIsNextButtonEnabled(false);
    setIsPrevButtonEnabled(true);
    swiperRef.current?.slideNext();
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on("slideChange", () => {
        const swiperInstance = swiperRef.current!;
        setIsPrevButtonEnabled(swiperInstance.isBeginning);
        setIsNextButtonEnabled(swiperInstance.isEnd);
      });
    }
  }, []);

  return (
    <section className="container container-custom position-relative pt-4 px-4 pb-5 bg-white mt-5 mb-5 mb-lg-0">
      <div className="row profile-box-main-lg justify-content-center p-lg-4">
        <p className='common-head'>Subscribe Our Plan</p>
        <div className="d-flex d-md-none position-absolute items-center w-auto  pt-5 pe-4 gap-4 z-10 mx-auto  left-50 bottom-0">
          <button
            onClick={handlePrevClick}
            className={`custom-prev-button border-0 me-1.5 bg-transparent ${
              isPrevButtonEnabled ? "w-[30px]" : "w-[40px]"
            }`}
          >
            <span
              className={isPrevButtonEnabled ? "opacity-40" : "opacity-100"}
            >
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="24" transform="matrix(-1 0 0 1 48 0)" fill="#E5F3FF"/>
                <path d="M25.5 27.75L21.75 24L25.5 20.25" stroke="#008AFC" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </span>
          </button>
          <button
            onClick={handleNextClick}
            className={`custom-next-button border-0 bg-transparent ${
              isNextButtonEnabled ? "" : ""
            } `}
          >
            <span
              className={isPrevButtonEnabled ? "opacity-100" : "opacity-40"}
            >
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="24" fill="#E5F3FF"/>
                <path d="M22.5 27.75L26.25 24L22.5 20.25" stroke="#008AFC" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </span>
          </button>
        </div>
        <Swiper
          modules={[Navigation]}
          spaceBetween={30}
          slidesPerView={1} 
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {profilePlans.map((plan, index) => (
            <SwiperSlide key={index}>
              <div className={`card text-center profile-plan-card ${index === 1 ? 'profile-plan-highlight' : ''}`}>
                <div className="card-body">
                  <h5 className="fw-normal color-dark font-s ff-plus">{plan.title}</h5>
                  <h2 className="font-xxl color-dark ff-plus font-medium">
                    {plan.price}{' '}
                    <small className="font-xs light">{plan.period}</small>
                  </h2>
                  <p className="color-light ff-plus font-xs fw-normal max-w-260 pb-1">{plan.description}</p>
                  <hr className="color-mediumblack" />
                  <div className="pt-2">
                    {plan.features.map((feature, i) => (
                      <div key={i} className="d-flex align-items-center pb-3 gap-1">
                        <img src="/svg/Icon.svg" alt="" style={{ marginRight: '8px' }} />
                        <div className="ff-inter color-dark font-xs text-nowrap fw-semibold d-flex align-items-center">
                          {feature}
                          {i === 0 || i === 1 || i === 4 ? (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ marginLeft: '8px' }}
                            >
                              <path
                                d="M8 5V8M8 11H8.0075M14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8Z"
                                stroke="#262626"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : null}
                          <i className="bi bi-info-circle" style={{ marginLeft: '8px' }}></i>
                        </div>
                      </div>
                    ))}
                  </div>
                  <button className="planbtn mt-3" onClick={subscribeClick}>
                    Get 14 Days Free Trial
                  </button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default ProfilePlans;

