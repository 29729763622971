import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import LoginButton from "../Auth/login";

const Header = () => {
  return (
    <header className="position-relative max-w-2xl mx-auto h-700 d-flex align-items-center pt-5 pt-lg-0">
      <Container className="custom-container px-lg-4 mx-auto">
        <Row className="align-items-center">
          <Col lg={7} className="-mt-5 px-3">
            <Button className="btn-2 mb-3">COSPLAY CORPS</Button>
            <h1 className="ff-anton text-xxl fw-normal text-start text-white">
              Welcome To <span className="text-gradient-1"> Cosplay</span>{" "}
              <span className="text-gradient-2"> Corps</span>
            </h1>
            <p className="heading-2 d-none d-lg-block font-medium ff-manrope lh-40 text-white text-start">
              Empowering Content Creators, Celebrating <br /> Creativity, and
              Building Community
            </p>
            <p className="heading-2 d-block d-lg-none font-medium ff-manrope lh-40 text-white text-start">
              Empowering Content Creators, Celebrating  Creativity, and
              Building Community
            </p>
            <LoginButton className="commonbtn mt-sm-4" alternateText="JOIN NOW" />
          </Col>
          <Col lg={5} className="px-0 d-flex justify-content-end">
            <img
              src="/images/corps.png"
              width={570}
              height={698}
              className="corpsimg"
              alt="Community Illustration"
            />
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
