import React, { useState, useCallback } from 'react';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import PhotoUploadForm from './PhotoUploadForm';
import PhotoApiProcessor from '../../api/PhotoApiProcessor';
import { PhotoUploadFormModel } from './Models/PhotoUploadFormModel';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const PhotoUpload = () => {
  const { conventionId, referenceCode } = useParams<{ conventionId?: string, referenceCode?: string }>();
  const { getAccessTokenSilently } = useAuth0();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const baseTemplate: any = {
    cosplayers: [],
    photographer: null,
    convention: conventionId || null,
    tags: [],
    description: '',
    date: null,
    groupingCode: referenceCode || '',
    files: [],
    reducePublicAccess: false,
    socialMedia: {
      facebook: false,
      instagram: false,
      twitter: false,
      threads: false,
    },
  };
  const [form, setForm] = useState<PhotoUploadFormModel>(baseTemplate);

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    setForm((prevForm) => ({ ...prevForm, files: [...prevForm.files, ...acceptedFiles] }));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
  });

  const handleRemoveFile = (file: File) => {
    setForm((prevForm) => ({ ...prevForm, files: prevForm.files.filter((f) => f !== file) }));
  };

  const handleSubmit = async () => {
    const photoApiProcessor = new PhotoApiProcessor(getAccessTokenSilently);
    try {
      setIsProcessing(true);
      await photoApiProcessor.uploadPhotos(form)
      // Reset form
      setForm(baseTemplate);
      // Show success toast
      toast.success('Upload Complete');
    } catch (error) {
      // Show error toast
      toast.error(`${error}`);
    }
    setIsProcessing(false);
  };

  return (
    <div className="photo-upload container">
      <h2>Upload Photos {(conventionId && referenceCode) && `for QuickShoot ${referenceCode}`}</h2>
      <div className="dropzone border p-3 mb-3 text-center" {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag And Drop Photos Here</p>
      </div>
      <aside>
        <div className="row">
          {form.files.map((file, index) => (
            <div key={index} className="col-md-2 mb-3">
              <div className="card">
                <img
                  className="card-img-top"
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  style={{ width: '100%', height: 'auto' }}
                />
                <div className="card-body">
                  <p className="card-text">{file.name}</p>
                  <button onClick={() => handleRemoveFile(file)} className="btn btn-danger">
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </aside>
      <PhotoUploadForm conventionId={Number(conventionId)} isProcessing={isProcessing} quickshootCode={referenceCode} form={form} setForm={setForm} handleSubmit={handleSubmit} />
    </div>
  );
};

export default PhotoUpload;
