import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { PhotoUploadFormModel } from "../pages/Photographs/Models/PhotoUploadFormModel";
import { toast } from "react-toastify";


class PhotoApiProcessor {
  private baseUrl = process.env.REACT_APP_COSPLAYCORPS_API_URL!;
  private domain = process.env.REACT_APP_AUTH0_DOMAIN!;
  private getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>;

  constructor(getAccessTokenParam: (options?: GetTokenSilentlyOptions) => Promise<string>) {
    this.getAccessToken = getAccessTokenParam;
  }
  
  async fetchPhotos(url: string, filters: Record<string, any> = {}) {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    const response = await fetch(`${url}&${new URLSearchParams(filters)}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    const parsedResult = await response.json();
    return { photos: parsedResult.data, totalPages: parsedResult.totalPages };
  }

  async downloadQuickShootImagesByQuickShootId(quickShootId: number, userId: bigint | undefined) {
    if(!userId){
      return;
    }
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    const formdata = new FormData();
    formdata.append("userId", userId.toString());

    try {
      const response = await fetch(`${this.baseUrl}/Photographs/Download/${quickShootId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formdata
      });

      if (response.status !== 200 && response.status !== 204) {
        toast.error("Error in zipping the images.");
        return;
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Photoshoot ${quickShootId}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL.createObjectURL reference
    } catch (ex) {

    }
  }

  async downloadQuickShootImages(userId: bigint, referenceCode: string, conventionId: number) {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    const formdata = new FormData();
    formdata.append("userId", userId.toString());

    try {
      const response = await fetch(`${this.baseUrl}/Photographs/Download/${referenceCode}/${conventionId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formdata
      });

      if (response.status !== 200 && response.status !== 204) {
        toast.error("Error in zipping the images.");
        return;
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Photoshoot ${referenceCode}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL.createObjectURL reference
    } catch (ex) {

    }
  }

  async deleteImage(photoId: string) {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    try {
      const response = await fetch(`${this.baseUrl}/Photographs/${photoId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        toast.error(`Image could not be deleted.`);
        return false;
      }
      return true;
    } catch (ex) {
      return false;
    }
  }

  async getPhotosByQuickShootId(quickShootId: string) {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });
    const response = await fetch(`${this.baseUrl}/photographs/QuickShoot/${quickShootId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    return await response.json();
  }


  async getPhotosByConventionIdAndReferenceCode(conventionId: string, referenceCode: string) {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    const response = await fetch(`${this.baseUrl}/photographs/QuickShoot/${conventionId}/${referenceCode}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    return await response.json();
  }

  async getPhotos(pageNumber: number, numPerPage: number) {
    const response = await fetch(`${this.baseUrl}/photographs?pageNumber=${pageNumber}&numPerPage=${numPerPage}`);
    return await response.json();
  }

  async getPhotoById(photoId: string) {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });
    
    const response = await fetch(`${this.baseUrl}/photographs/${photoId}`,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      }
    );
    return await response.json();
  }

  async uploadPhotos(form: PhotoUploadFormModel) {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });
    const formdata = new FormData();
    form.files.forEach(file => formdata.append("Files", file, file.name));


    // // Append cosplayers
    form.cosplayers.forEach(cosplayer => formdata.append('Cosplayers', cosplayer.value));
    formdata.append('Photographer', form.photographer?.value || '');

    if (form.convention) {
      formdata.append('Convention', form.convention?.value);
    }

    if (form.groupingCode) {
      formdata.append('GroupingCode', form.groupingCode);
    }

    if (form.tags.length !== 0) {
      form.tags.forEach(tag => {
        var tagToAppend = tag.value.toLowerCase();
        formdata.append("Tags", tagToAppend)
      }
      );
    }

    if (form.description) {
      formdata.append('Description', form.description);
    }

    formdata.append('Date', form.date ? form.date.toISOString() : '');
    formdata.append('ReducePublicAccess', form.reducePublicAccess.toString());
    formdata.append('PostToFacebook', form.socialMedia.facebook.toString());
    formdata.append('PostToInstagram', form.socialMedia.instagram.toString());
    formdata.append('PostToTwitter', form.socialMedia.twitter.toString());
    formdata.append('PostToThreads', form.socialMedia.threads.toString());

    try {
      const response = await fetch(`${this.baseUrl}/Photographs/Upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formdata,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.log('Response error text:', errorText);
        throw new Error('Service failed to upload photos');
      }

      if (response.ok && response.headers.get('Content-Type')?.includes('application/json')) {
        // Parse the JSON content
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch (ex) {
      console.log('Error during upload:', ex);
      throw new Error('Request invalid. Check inputs.');
    }
  }
}

export default PhotoApiProcessor;
