import FeatureData from '../../components/LandingPage/FeatureData'
import Footer from '../../components/LandingPage/Footer'
import Header from '../../components/LandingPage/LandingHero'
import OurFeatures from '../../components/LandingPage/OurFeatures'
import './Landing.css'
import './Home.css'
import LandingNavBar from '../../components/LandingPage/LandingNavBar'

const LandingPage = () => {
  return (
    <>
      <div className="bg-grey overflow-hidden">
        <LandingNavBar />
        <Header />
      </div>
      <FeatureData />
      <OurFeatures />
      <Footer />
    </>
  )
}

export default LandingPage
