import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  requiredRoles?: string[]; // Add the required role as a prop
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  requiredRoles,
  ...rest
}) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    //if the user is not authenticated and we have loaded then we need to redirect them to login.
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: { returnTo: location.pathname }, // Save the current path
      });
    }
    // if the user is authenticated and we have loaded and they have an id of 0 then we need them to finish signing up.
    if (!isLoading && isAuthenticated) {
      const onboarded =
        user && user["https://cosplaycorps.com/CosplayCorpsId"] > 0;
      if (!onboarded) {
        navigate("/sign-up-wizard");
      }
    }

    if (user && requiredRoles) {
      // User is valid and good to go so lets check the role
      const roles: string[] = user["https://cosplaycorps.com/roles"] || []; // Assuming roles are stored in a custom claim
      const hasRequiredRole = requiredRoles.some(role => roles.includes(role));
      if (!hasRequiredRole) {
        navigate("/unauthorized"); // Redirect to unauthorized page if the role check fails
        return; // Exit early to prevent component rendering
      }
    }
  }, [user, isAuthenticated, isLoading, navigate]);

  if (isLoading && isAuthenticated) {
    return <div>Loading...</div>;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
