import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaHome, FaImages, FaBars } from 'react-icons/fa';
import { TbPhotoCirclePlus } from 'react-icons/tb'
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../Auth/login';
import LogoutButton from '../Auth/logout';


const MobileBottomNavbar: React.FC = () => {
  const [show, setShow] = useState(false);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [requiresOnboarding, setRequiresOnboarding] = useState(false);

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      setRequiresOnboarding(user['https://cosplaycorps.com/CosplayCorpsId'] == 0);
    }
  }, [user, isAuthenticated, isLoading, requiresOnboarding, setRequiresOnboarding]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    !requiresOnboarding ?
    <>
      <Navbar bg="light" className="sticky-bottom justify-content-around w-100">
        <Nav className="d-flex justify-content-around w-100">

          <Nav.Link as={NavLink} to="/dashboard" className="text-center">
            <FaHome size={20} />
            <div className="small">Dashboard</div>
          </Nav.Link>

          <Nav.Link as={NavLink} to="/Gallery" className="text-center">
            <FaImages size={20} />
            <div className="small">Gallery</div>
          </Nav.Link>

          {isAuthenticated && user && user['https://cosplaycorps.com/roles'].includes("Photographer") &&
            <Nav.Link as={NavLink} to="/adhocPhotoShoot" className="text-center">
              <TbPhotoCirclePlus size={20} />
              <div className="small">Quick Shoot</div>
            </Nav.Link>}

          {/* {isAuthenticated && <Nav.Link as={NavLink} to="/upload" className="text-center">
            <FaUpload size={20} />
            <div className="small">Upload</div>
          </Nav.Link>} */}

          <Nav.Link as={NavLink} to="#" onClick={handleShow} className="text-center">
            <FaBars size={20} />
            <div className="small">Menu</div>
          </Nav.Link>

        </Nav>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         
          <Nav className="flex-column text-center">
            {isAuthenticated && <Nav.Link as={NavLink} to="/dashboard" onClick={handleClose}>
              My Dashboard
            </Nav.Link>}
            <Nav.Link as={NavLink} to="/Gallery" onClick={handleClose}>
              Gallery
            </Nav.Link>
            {isAuthenticated && <Nav.Link as={NavLink} to="/upload" onClick={handleClose}>
              Upload
            </Nav.Link>}
            {isAuthenticated && user && user['https://cosplaycorps.com/roles'].includes("Photographer") &&
              <Nav.Link as={NavLink} to="/adhocPhotoShoot" className="nav-link">
                Quick Shoot
              </Nav.Link>}
            {isAuthenticated && user && user['https://cosplaycorps.com/roles'].includes("Cosplayer") &&
              <Nav.Link as={NavLink} to="/searchquickshoot" className="nav-link">
                Search Quick Shoot
              </Nav.Link>}

              <div className="mb-4">
            {isAuthenticated ? <LogoutButton /> : <LoginButton />}
          </div>
            {/* Add more links as needed */}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </> : <></>
  );
};

export default MobileBottomNavbar;