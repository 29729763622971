import React, { useState } from 'react';
import './ChooseRole.css'; // Make sure to import the CSS file

interface ChooseRoleProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  nextStep: () => void;
}

const ChooseRole: React.FC<ChooseRoleProps> = ({ formData, setFormData, nextStep }) => {
  const [selectedRole, setSelectedRole] = useState<string | null>(null);

  const roles = [
    {
      name: 'Cosplayer',
      image: '/images/CosplayerRole.webp',
      benefits: [
        'Publish subscriber content',
        'Register for photoshoots',
        'View engagement metrics',
      ],
    },
    {
      name: 'General User',
      image: '/images/GenericRole.png',
      benefits: [
        'Track convention attendance',
        'View details about events at conventions'
      ],
    },
    {
      name: 'Photographer',
      image: '/images/PhotographerRole.webp',
      benefits: [
        'Easily Organize and Share Photoshoots on the go',
        'Convention Photoshoot Reservations',
        'Convention Evaluation Tooling',
      ],
    }
  ];

  const handleRoleSelect = (role: string) => {
    if (selectedRole === role) {
      setSelectedRole(null);
      setFormData((prev: any) => ({ ...prev, role: undefined }));
    } else {
      setSelectedRole(role);
      setFormData((prev: any) => ({ ...prev, role }));
      nextStep();
    }
  };

  return (
    <div className="container choose-role">
      <h2 className="text-center mb-4">Choose Your Role</h2>
      <div className="roles-container d-flex justify-content-center flex-wrap">
        {roles.map((role) => (
          <div
            key={role.name}
            className={`role-card role-image-container ${selectedRole === role.name ? 'selected' : ''}`}
            onClick={() => handleRoleSelect(role.name)}
            style={{ cursor: 'pointer', position: 'relative', margin: '10px', textAlign: 'center' }}
          >
            <img src={role.image} alt={role.name} className="img-fluid role-image" />
            <div className={`role-overlay ${selectedRole === role.name ? 'show' : ''}`}>
              <h3 className="mt-2">{role.name}</h3>
              <ul className="benefits-list">
                {role.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseRole;