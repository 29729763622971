import useSWR from 'swr';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { loadConventionsFromLocalStorage, saveConventionsToLocalStorage } from '../localStorageUtils';
import { Convention } from '../../../api/ApiModels/Models';
import ConventionApi from '../../../api/ConventionApi';

const dedupeConventions = (conventions: Convention[]): Convention[] => {
  const uniqueConventions = new Map();
  conventions.forEach((convention) => uniqueConventions.set(convention.id, convention));
  return Array.from(uniqueConventions.values());
};

const filterConventions = (conventions: Convention[], conventionFilters: Record<string, any>): Convention[] => {
  return conventions.filter(convention => {
    const nameContainsText = conventionFilters.name ? convention.name.toLowerCase().includes(conventionFilters.name.toLowerCase()) : true;
    const matchesState = conventionFilters.state ? convention.location.state === conventionFilters.state : true;
    const matchesDate = conventionFilters.startDate && conventionFilters.endDate
      ? new Date(convention.startDate) >= new Date(conventionFilters.startDate) && new Date(convention.endDate) <= new Date(conventionFilters.endDate)
      : true;
    const matchesBounds = conventionFilters.north && conventionFilters.south && conventionFilters.east && conventionFilters.west
      ? convention.location.latitude <= conventionFilters.north &&
        convention.location.latitude >= conventionFilters.south &&
        convention.location.longitude <= conventionFilters.east &&
        convention.location.longitude >= conventionFilters.west
      : true;
    return matchesState && matchesDate && matchesBounds && nameContainsText;
  });
};

export const useConventions = (conventionFilters: Record<string, any>) => {
  const { getAccessTokenSilently } = useAuth0();
  const [localConventions, setLocalConventions] = useState<Convention[]>([]);

  useEffect(() => {
    const cachedConventions = loadConventionsFromLocalStorage() || [];
    setLocalConventions(cachedConventions);
  }, []);

  const { data, error, mutate } = useSWR('conventions', () => new ConventionApi(getAccessTokenSilently).fetchAllConventions(), {
    dedupingInterval: 60000, // Cache for 1 minute
  });

  const allConventions = dedupeConventions([...(data || []), ...localConventions]);

  // Save to local storage whenever conventions are updated
  useEffect(() => {
    if (allConventions.length > 0) {
      saveConventionsToLocalStorage(allConventions);
    }
  }, [allConventions]);

  const filteredConventions = filterConventions(allConventions, conventionFilters);

  return {
    conventions: filteredConventions,
    error,
    isLoading: !error && !data,
    mutate,
  };
};