import React, { useState } from 'react';

type StateOption = {
  key: number;
  label: string;
};

export function getStateOrTerritoryByKey(key: number | string) {
    if(typeof key === 'string'){
      key = Number(key);
    }
    const stateOrTerritory = statesAndTerritories.find(item => item.key === key);
    return stateOrTerritory ? stateOrTerritory.label : null;
  }

const statesAndTerritories: StateOption[] = [
  { key: 1, label: 'Alabama (AL)' },
  { key: 2, label: 'Alaska (AK)' },
  { key: 3, label: 'Arizona (AZ)' },
  { key: 4, label: 'Arkansas (AR)' },
  { key: 5, label: 'California (CA)' },
  { key: 6, label: 'Colorado (CO)' },
  { key: 7, label: 'Connecticut (CT)' },
  { key: 8, label: 'Delaware (DE)' },
  { key: 9, label: 'Florida (FL)' },
  { key: 10, label: 'Georgia (GA)' },
  { key: 11, label: 'Hawaii (HI)' },
  { key: 12, label: 'Idaho (ID)' },
  { key: 13, label: 'Illinois (IL)' },
  { key: 14, label: 'Indiana (IN)' },
  { key: 15, label: 'Iowa (IA)' },
  { key: 16, label: 'Kansas (KS)' },
  { key: 17, label: 'Kentucky (KY)' },
  { key: 18, label: 'Louisiana (LA)' },
  { key: 19, label: 'Maine (ME)' },
  { key: 20, label: 'Maryland (MD)' },
  { key: 21, label: 'Massachusetts (MA)' },
  { key: 22, label: 'Michigan (MI)' },
  { key: 23, label: 'Minnesota (MN)' },
  { key: 24, label: 'Mississippi (MS)' },
  { key: 25, label: 'Missouri (MO)' },
  { key: 26, label: 'Montana (MT)' },
  { key: 27, label: 'Nebraska (NE)' },
  { key: 28, label: 'Nevada (NV)' },
  { key: 29, label: 'New Hampshire (NH)' },
  { key: 30, label: 'New Jersey (NJ)' },
  { key: 31, label: 'New Mexico (NM)' },
  { key: 32, label: 'New York (NY)' },
  { key: 33, label: 'North Carolina (NC)' },
  { key: 34, label: 'North Dakota (ND)' },
  { key: 35, label: 'Ohio (OH)' },
  { key: 36, label: 'Oklahoma (OK)' },
  { key: 37, label: 'Oregon (OR)' },
  { key: 38, label: 'Pennsylvania (PA)' },
  { key: 39, label: 'Rhode Island (RI)' },
  { key: 40, label: 'South Carolina (SC)' },
  { key: 41, label: 'South Dakota (SD)' },
  { key: 42, label: 'Tennessee (TN)' },
  { key: 43, label: 'Texas (TX)' },
  { key: 44, label: 'Utah (UT)' },
  { key: 45, label: 'Vermont (VT)' },
  { key: 46, label: 'Virginia (VA)' },
  { key: 47, label: 'Washington (WA)' },
  { key: 48, label: 'West Virginia (WV)' },
  { key: 49, label: 'Wisconsin (WI)' },
  { key: 50, label: 'Wyoming (WY)' },
  { key: 51, label: 'American Samoa (AS)' },
  { key: 52, label: 'Guam (GU)' },
  { key: 53, label: 'Northern Mariana Islands (MP)' },
  { key: 54, label: 'Puerto Rico (PR)' },
  { key: 55, label: 'U.S. Virgin Islands (VI)' },
  { key: 56, label: 'District of Columbia (DC)' }
];

interface StateSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {}

const StateSelect: React.FC<StateSelectProps> = (props) => {
  const [selectedValue, setSelectedValue] = useState<string | number>('');

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleClear = () => {
    setSelectedValue('');
  };

  return (
    <div>
      <select value={selectedValue} onChange={handleChange} {...props}>
        <option value="">Select a state or territory</option>
        {statesAndTerritories.map((state) => (
          <option key={state.key} value={state.key}>
            {state.label}
          </option>
        ))}
      </select>
      {selectedValue && (
        <button type="button" onClick={handleClear}>
          Clear
        </button>
      )}
    </div>
  );
};

export default StateSelect;