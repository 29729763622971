export enum QuickShootStatus {
    NotStarted = 0,
    EditingInProgress = 1,
    Complete = 2
}

export interface QuickShoot {
    /**
     * Id of the Quickshoot
     */
    id: number;

    /**
     * Id of the Convention that the quickshoot took place
     */
    conventionId: number;

    /**
     * Name of the convention that the quickshoot took place.
     */
    conventionName: string;

    /**
     * This is the ID of the User who generated the Quickshoot.
     */
    userId: bigint;

    /**
     * This is the reference code that is publicly shared to view the albums explicitly.
     */
    referenceCode: string;

    dateCreated: Date;
    dateModified: Date;

    /**
     * This is the handle of the intended recipient. This is not validated
     */
    instagramHandle: string;

    status: QuickShootStatus;
}
