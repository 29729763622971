// src/BaseApi.ts
import { GetTokenSilentlyOptions } from '@auth0/auth0-react';

export class BaseApi {
  protected baseUrl: string;
  protected domain: string;
  protected getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>;

  constructor(getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>) {
    this.baseUrl = process.env.REACT_APP_COSPLAYCORPS_API_URL!;
    this.domain = process.env.REACT_APP_AUTH0_DOMAIN!;
    this.getAccessToken = getAccessToken;
  }

  // Helper method to make requests
  protected async makeRequest<T>(url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', body?: any): Promise<T | null> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    const options: RequestInit = {
      method,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${this.baseUrl}${url}`, options);

    if (!response.ok) {
      console.error(`Network response was not ok for URL: ${url}`);
      return null;
    }

    return response.json() as Promise<T>;
  }

  protected async executeAction(url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', body?: any): Promise<boolean> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    const options: RequestInit = {
      method,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${this.baseUrl}${url}`, options);

    if (!response.ok) {
      console.error(`Network response was not ok for URL: ${url}`);
      return false;
    }
    return true;
  }
}