// src/api/ConventionApi.ts
import { ConventionDetailData, Convention, WeatherData, PhotoBookingSchedule } from './ApiModels/Models';
import { BaseApi } from './Base/BaseApi';

export class ConventionApi extends BaseApi {
  async createPhotoshootSchedule(data: PhotoBookingSchedule): Promise<boolean> {
    return await this.makeRequest<boolean>(`/Conventions/${data.conventionId}/Photoshoots`, "PUT", data) ?? false;
  }

  async fetchConventionDetails(id: string | undefined): Promise<ConventionDetailData | null> {
    if (!id) {
      console.error('Invalid convention ID');
      return null;
    }
    return this.makeRequest<ConventionDetailData>(`/conventions/${id}/details`, "POST");
  }

  async fetchConventionWeather(latitude: number, longitude: number): Promise<WeatherData[] | null> {
    if (!latitude || !longitude) {
      console.error('Invalid coordinates');
      return null;
    }
    return await this.makeRequest<WeatherData[]>(`/conventions/weather`, 'POST', { latitude, longitude });
  }

  async fetchConvention(id: string | undefined): Promise<Convention | null> {
    if (!id) {
      console.error('Invalid convention ID');
      return null;
    }
    return this.makeRequest<Convention>(`/conventions/${id}`);
  }

  async attendConvention(conventionId: number, badgePrice: number, isAttending: boolean): Promise<boolean> {
    try{
      const result = await this.makeRequest<boolean>(`/conventions/${conventionId}/attend`, 'POST', { badgePrice, isAttending });
    return result !== null;
    }catch{
      return false;
    }
  }

  async fetchConventionNames(): Promise<Convention[] | null> {
    return this.makeRequest<Convention[]>(`/conventions`);
  }

  async fetchConventions(page: number, numPerPage: number, filter: any, filters: Record<string, any> = {}): Promise<any> {
    const queryParams = new URLSearchParams({ ...filters, page: page.toString(), numPerPage: numPerPage.toString() });
    return this.makeRequest<any>(`/conventions?${queryParams}`);
  }

  async fetchAllConventions(): Promise<Convention[] | null> {
    return this.makeRequest<Convention[]>(`/conventions`);
  }
}

export default ConventionApi;