import { ProfileData } from '../interfaces/Profile';
import { ProfileEditForm } from '../pages/User/ProfileEditFormComponent';
import { BaseApi } from './Base/BaseApi';

export interface UserDetails {
  completedSignUp: boolean;
  Auth0Id: string;
}

class UserApiProcessor extends BaseApi {
  async fetchUserProfile(userName: string): Promise<ProfileData | null> {
    return await this.makeRequest<ProfileData>(`/profile/${userName}`);
  }

  async updateProfile(profileData: ProfileEditForm, ccUser: any): Promise<boolean> {
    // Prepare the request payload
    const payload = {
      twitch: profileData.twitch,
      facebook: profileData.facebook,
      instagram: profileData.instagram,
      x: profileData.x,
      youTube: profileData.youTube,
      linkedIn: profileData.linkedIn,
      user: {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        auth0Id: ccUser.Auth0Id,
        emailAddress: ccUser.EmailAddress
        // Add any other necessary fields that are part of the user
      },
    };
    return (await this.executeAction('/Users/Update', 'POST', payload)) ?? false;
  }
  
  async fetchUserNames(): Promise<string[]> {
    return await this.makeRequest<string[]>(`/users/usernames`) ?? [];
  }

  async checkUserNameAvailability(userName: string): Promise<boolean> {
    //endpoint returns true if username is found
    var temp = await this.makeRequest<boolean>(`/users/availability/${userName}`);
    return temp ?? false;
  }

  async fetchUserDetails(userId: string): Promise<UserDetails | null> {
    return await this.makeRequest(`/profile/${userId}`);
  }

  async processEnrollment(formData: FormData): Promise<UserDetails | null> {
    this.executeAction(`/Users/Create`, "POST", formData);
    return null;
  }
}

export default UserApiProcessor;
