import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import PhotoApiProcessor from "../../api/PhotoApiProcessor";
import { useAuth0 } from "@auth0/auth0-react";
import { Photograph } from "./Models/Photograph";
import { ButtonGroup } from "react-bootstrap";
import PersonCardComponent from "../../components/common/PersonCardComponent";
import { FaHeart, FaShare } from "react-icons/fa";

const PhotoDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [photo, setPhoto] = useState<Photograph | null>(null);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const fetchPhoto = async () => {
    const photoApiProcessor = new PhotoApiProcessor(getAccessTokenSilently);
    try {
      const temp = await photoApiProcessor.getPhotoById(id!);
      if (temp.imageUri.startsWith("/")) {
        temp.imageUri = `https://storage.cosplaycorps.com${temp.imageUri}`;
      }
      setPhoto(temp);

      if (
        isAuthenticated &&
        user &&
        user["https://cosplaycorps.com/CosplayCorpsId"] == temp.ownerId
      ) {
        setIsOwner(true);
      }
    } catch (error) {
      console.error("Error fetching photo:", error);
    }
  };

  useEffect(() => {
    fetchPhoto();
  }, [id]);

  const handleBackClick = () => {
    navigate(location.state?.from || "/");
  };

  const deleteImageClick = async () => {
    const photoApiProcessor = new PhotoApiProcessor(getAccessTokenSilently);
    if (await photoApiProcessor.deleteImage(photo?.id!)) {
      navigate(location.state?.from || "/");
    }
  };

  if (!photo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="">
            <img
              src={photo.imageUri}
              alt={photo.title}
              className="custom-img"
            />
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className="mb-2 d-flex justify-content-between">
            <button className="btn btn-secondary" onClick={handleBackClick}>
              Back
            </button>

            <ButtonGroup>
              <button className="btn btn-secondary" onClick={handleBackClick}>
                Like <FaHeart />
              </button>
              <button className="btn btn-secondary" onClick={handleBackClick}>
                Share <FaShare />
              </button>
              {isOwner && (
                <button onClick={deleteImageClick} className="btn btn-danger">
                  Delete
                </button>
              )}
            </ButtonGroup>
          </div>
          <div className="">
            <p className="">{photo.description}</p>
            <p className="">
              <strong>Cosplayers:</strong>
              {photo.cosplayers &&
                photo.cosplayers.map((cosplayer: any, index) => {
                  return <PersonCardComponent userName={cosplayer} />;
                })}
            </p>
            <p className="">
              <strong>Photographer:</strong>
              <PersonCardComponent userName={photo.photographer} />
            </p>
            {/* Add more fields as needed */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoDetails;
