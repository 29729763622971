import React, { useState, useEffect } from 'react';
import StateSelect from '../System/StateSelect';

interface ConventionFilterFields {
  name?: string;
  startDate?: string;
  endDate?: string;
  state?: string;
  ignoreMap?: boolean;
}

interface FiltersProps {
  onFilterChange: (filters: ConventionFilterFields) => void;
}

const ConventionFilters: React.FC<FiltersProps> = ({ onFilterChange }) => {
  const [conventionName, setConventionName] = useState('');
  const [conventionStartDate, setConventionStartDate] = useState('');
  const [conventionEndDate, setConventionEndDate] = useState('');
  const [conventionState, setConventionState] = useState('');
  const [ignoreMap, setIgnoreMap] = useState(false);

  const handleFilterChange = () => {
    onFilterChange({ 
      name: conventionName, 
      startDate: conventionStartDate, 
      endDate: conventionEndDate, 
      state: conventionState,
      ignoreMap
    });
  };

  useEffect(() => {
    handleFilterChange();
  }, [ignoreMap]);

  const clearStartDate = () => {
    setConventionStartDate('');
    handleFilterChange();
  };

  const clearEndDate = () => {
    setConventionEndDate('');
    handleFilterChange();
  };

  return (
    <div className="filter-form">
      <div className="form-group">
        <label>Convention Name</label>
        <input
          type="text"
          className="form-control"
          value={conventionName}
          onChange={(e) => setConventionName(e.target.value)}
          onKeyUp={handleFilterChange}
        />
      </div>
      <div className="form-group">
        <label>Start Date</label>
        <div className="input-group">
          <input
            type="date"
            className="form-control"
            value={conventionStartDate}
            onChange={(e) => setConventionStartDate(e.target.value)}
            onBlur={handleFilterChange}
          />
          {conventionStartDate && (
            <div className="input-group-append">
              <button className="btn btn-outline-secondary" type="button" onClick={clearStartDate}>
                Clear
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <label>End Date</label>
        <div className="input-group">
          <input
            type="date"
            className="form-control"
            value={conventionEndDate}
            onChange={(e) => setConventionEndDate(e.target.value)}
            onBlur={handleFilterChange}
          />
          {conventionEndDate && (
            <div className="input-group-append">
              <button className="btn btn-outline-secondary" type="button" onClick={clearEndDate}>
                Clear
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <label>State</label>
        <StateSelect 
          onBlur={handleFilterChange}
          value={conventionState}
          className="form-control"
          onChange={(e) => setConventionState(e.target.value)}
        />
      </div>
      {/* <div className="form-group form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="ignoreMap"
          checked={ignoreMap}
          onChange={(e) => setIgnoreMap(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="ignoreMap">Ignore Map Boundaries</label>
      </div> */}
    </div>
  );
};

export default ConventionFilters;