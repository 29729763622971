import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <footer className="bg-dark">
        <div className="custom-container px-4 px-xl-0 mx-auto py-4 d-flex flex-column flex-sm-row align-items-center justify-content-between">
          <Link to="/">
            <img src="/svg/footerlogo.svg" alt="footerlogo" />
          </Link>
          <div className="d-flex gap-4 pt-4 pt-sm-0">
            {/* <a href="https://x.com/?lang=en" target="_blank" rel="noreferrer">
              <div className="footer-icons">
                <img src="/svg/twitter.svg" alt="twitter" />
              </div>
            </a> */}
            <a
              href="https://www.facebook.com/profile.php?id=61564776774904"
              target="_blank"
              rel="noreferrer"
            >
              <div className="footer-icons">
                <img src="/svg/fb.svg" alt="fb" />
              </div>
            </a>
            {/* <a
              href="https://www.linkedin.com//"
              target="_blank"
              rel="noreferrer"
            >
              <div className="footer-icons">
                <img src="/svg/linkedin.svg" alt="linkedin" />
              </div>
            </a> */}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
