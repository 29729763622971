import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { PhotoUploadFormModel } from "../pages/Photographs/Models/PhotoUploadFormModel";
import { QuickShoot, QuickShootStatus } from "../interfaces/QuickShoot";
import { toast } from 'react-toastify';

class QuickShootsApi {
  private baseUrl = process.env.REACT_APP_COSPLAYCORPS_API_URL!;
  private domain = process.env.REACT_APP_AUTH0_DOMAIN!;
  private getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>;

  constructor(getAccessTokenParam: (options?: GetTokenSilentlyOptions) => Promise<string>) {
    this.getAccessToken = getAccessTokenParam;
  }

  async reserveQuickShoot(instagramHandle: string, conventionId: string, username?: string): Promise<QuickShoot> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });
    const formData = new FormData();
    formData.append('InstagramHandle', instagramHandle);
    if (username) {
      formData.append('CosplayCorpsUserName', username || "");
    }

    try {
      const response = await fetch(`${this.baseUrl}/Photoshoots/QuickShoot/${conventionId}`, {
        method: 'post',
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to Reserve: ${response.statusText}`);
      }

      const referenceCode = await response.json();
      return referenceCode;

    } catch (error) {
      console.log('Fetch Error:', error);
      throw new Error('Failed to Reserve');
    }
  }
  async retrieveQuickShootById(quickShootId: string): Promise<QuickShoot | null> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    try {
      const response = await fetch(`${this.baseUrl}/Photoshoots/QuickShoot/${quickShootId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
      if (response.status !== 200 && response.status !== 204) {
        toast.error(`Please retry...`);
      }

      if (response.status === 204) {
        return null;
      }
      return response.json();
    } catch (error) {
      console.log('Fetch Error:', error);
      toast.error(`Nothing was found.`);
    }
    return null;
  }

  async retrieveQuickShoot(conventionId: string, referenceCode: string): Promise<QuickShoot | null> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    try {
      const response = await fetch(`${this.baseUrl}/photoshoots/QuickShoot/${conventionId}/${referenceCode}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
      if (response.status !== 200 && response.status !== 204) {
        toast.error(`Please retry...`);
      }

      if (response.status === 204) {
        return null;
      }
      return response.json();
    } catch (error) {
      console.log('Fetch Error:', error);
      toast.error(`Nothing was found.`);
    }
    return null;
  }

  async updateQuickShootStatus(quickShootId: number, quickShootStatus: QuickShootStatus): Promise<string> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    let status = "";

    switch (quickShootStatus) {
      case QuickShootStatus.NotStarted:
        status = "NotStarted";
        break;
      case QuickShootStatus.EditingInProgress:
        status = "EditingInProgress";
        break;
      case QuickShootStatus.Complete:
        status = "Complete";
        break;
    }

    const params = new URLSearchParams({ status });

    try {
      const response = await fetch(`${this.baseUrl}/Photoshoots/QuickShoot/${quickShootId}?${params.toString()}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to Update: ${response.statusText}`);
      }

      const referenceCode = await response.text();
      toast.success("Quickshoot status updated")
      return referenceCode;

    } catch (error) {
      console.log('Fetch Error:', error);
      toast.error("Failed to update status of quickshoot.")
      return "";
    }
  }

  async deleteQuickShoot(quickShootId: number): Promise<boolean> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });

    try {
      const response = await fetch(`${this.baseUrl}/photoshoots/QuickShoot/${quickShootId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      if (!response.ok) {
        toast.error("Deletion Failed.");
        return false;
      }

      await response.text();
      return true;

    } catch (error) {
      console.log('Fetch Error:', error);
      toast.error("Failed to Delete");
    }
    return false;
  }


  async uploadPhotos(form: PhotoUploadFormModel) {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });
    const formdata = new FormData();
    form.files.forEach(file => formdata.append("Files", file, file.name));


    // // Append cosplayers
    form.cosplayers.forEach(cosplayer => formdata.append('Cosplayers', cosplayer.value));
    formdata.append('Photographer', form.photographer?.value || '');

    if (form.convention) {
      formdata.append('Convention', form.convention?.value);
    }

    if (form.groupingCode) {
      formdata.append('GroupingCode', form.groupingCode);
    }

    if (form.tags.length !== 0) {
      form.tags.forEach(tag => {
        var tagToAppend = tag.value.toLowerCase();
        formdata.append("Tags", tagToAppend)
      }
      );
    }

    if (form.description) {
      formdata.append('Description', form.description);
    }

    formdata.append('Date', form.date ? form.date.toISOString() : '');
    formdata.append('PostToFacebook', form.socialMedia.facebook.toString());
    formdata.append('PostToInstagram', form.socialMedia.instagram.toString());
    formdata.append('PostToTwitter', form.socialMedia.twitter.toString());
    formdata.append('PostToThreads', form.socialMedia.threads.toString());

    try {
      const response = await fetch(`${this.baseUrl}/Photographs/Upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formdata,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.log('Response error text:', errorText);
        toast('Service failed to upload photos');
      }

      if (response.ok && response.headers.get('Content-Type')?.includes('application/json')) {
        // Parse the JSON content
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch (ex) {
      console.log('Error during upload:', ex);
      toast.error(`Error occured during upload. Please refresh and try again.`);
    }
  }
}

export default QuickShootsApi;
