import React from "react";
import FeatureSection from "./FeatureSection";

interface FeatureDataItem {
  title: string;
  description: string;
  buttonText: string;
  imageSrc: string;
  reverse?: boolean; 
  buttonTextColor?: string; 
  imageBgColor?: string; 
  customStyle?: React.CSSProperties; 
  textColumnStyle?: React.CSSProperties; 
  paragraphMaxWidth?: string; 
}

const featureData: FeatureDataItem[] = [
  {
    title: "GROW THE COMMUNITY",
    description:
      "Connect with cosplayers and photographers from around the world. Share your passion and build lasting friendships.",
    buttonText: "COMMUNITY",
    imageSrc: "/images/growcommunity.png",
    reverse: false,
    buttonTextColor: "#9247F5", 
    imageBgColor: "#CEEBCF", 
    paragraphMaxWidth: "529px", 
  },
  {
    title: "PROMOTE CREATIVITY",
    description:
      "Showcase your work and inspire others. Our platform is for promoting your creativity.",
    buttonText: "CREATIVITY",
    imageSrc: "/images/promotecreativity.png",
    reverse: true, 
    buttonTextColor: "#008AFC",
    imageBgColor: "#D9EAFB", 
    textColumnStyle: { paddingLeft: "80px" }, 
    paragraphMaxWidth: "405px", 
  },
  {
    title: "EMPOWER CONTENT CREATORS",
    description:
      "Gain exposure, book photoshoots, and track engagement. Our tools are built to support your growth and success.",
    buttonText: "CREATORS",
    imageSrc: "/images/creators.png",
    reverse: false,
    buttonTextColor: "#11C9A6", 
    imageBgColor: "#D7CFF9", 
    paragraphMaxWidth: "509px", 
  },
];

const FeatureData: React.FC = () => {
  return (
    <div className="App">
      {featureData.map((feature, index) => (
        <FeatureSection
          key={index}
          title={feature.title}
          description={feature.description}
          buttonText={feature.buttonText}
          imageSrc={feature.imageSrc}
          reverse={feature.reverse}
          buttonTextColor={feature.buttonTextColor}
          imageBgColor={feature.imageBgColor}
          customStyle={feature.customStyle}
          textColumnStyle={feature.textColumnStyle}
          paragraphMaxWidth={feature.paragraphMaxWidth}
        />
      ))}
    </div>
  );
};

export default FeatureData;
