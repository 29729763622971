import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserApiProcessor, { UserDetails } from "../../api/UserApiProcessor";


const Callback: React.FC = () => {
  const navigate = useNavigate();
  const { error, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const profileApiProcessor = new UserApiProcessor(getAccessTokenSilently);

  if (error) {
    <span>{error.message}</span>

  }

  const checkUserMetadata = async () => {
    if (isAuthenticated && user) {
      try {
        const userDetails: UserDetails | null = await profileApiProcessor.fetchUserDetails(user.sub!);

        if (!userDetails || !userDetails.completedSignUp) {
          navigate('/sign-up-wizard');
        }
      } catch (e) {
        console.error('Error checking user metadata:', e);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      checkUserMetadata();

      const returnTo = localStorage.getItem('returnTo') || '/';
      localStorage.removeItem('returnTo');
      navigate(returnTo);
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="content-layout">
      <h1 id="page-title" className="content__title">
        Error
      </h1>
      <div className="content__body">
        <p id="page-description">
          Hey
        </p>
      </div>
    </div>
);
};

export default Callback;