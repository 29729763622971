import React, { useCallback, useRef } from 'react';
import Masonry from 'react-masonry-css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Photograph } from '../../pages/Photographs/Models/Photograph';

interface MasonryGalleryComponentProps {
    photos: Array<Photograph>;
    onBottomReached?: () => void;
    columnOverrides?: any | null;
}

export const MasonryGalleryComponent: React.FC<MasonryGalleryComponentProps> = ({ photos, onBottomReached, columnOverrides }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const observer = useRef<IntersectionObserver | null>(null);

    const lastPhotoElementRef = useCallback(
        (node: HTMLElement | null) => {
            if (!onBottomReached) return; // Safely handle null or undefined

            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    onBottomReached(); // Call the parent function when bottom is reached
                }
            });

            if (node) observer.current.observe(node);
        },
        [onBottomReached]
    );


    const handlePhotoClick = (id: string) => {
        navigate(`/photo/${id}`, { state: { from: location } });
    };


    const breakpointColumnsObj = {
        default: 10,
        4900: 9,
        4370: 8,
        3890: 7,
        3400: 6,
        2900: 5,
        2400: 4,
        1945: 3,
        1480: 2,
        805: 1,
    };

    return (
        <Masonry
            breakpointCols={columnOverrides ?? breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
        >
            {photos.map((photo, index) => {
                if (lastPhotoElementRef != null && photos.length === index + 1) {
                    return (
                        <div ref={lastPhotoElementRef} key={photo.id} className='d-flex'>
                            <div className="card photo-container" onClick={() => handlePhotoClick(photo.id)}>
                                <img
                                    src={photo.imageUri.startsWith('/') ? `https://storage.cosplaycorps.com${photo.imageUri}` : photo.imageUri}
                                    alt={photo.title}
                                    className="card-img-top scaled-img"
                                />
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div key={photo.id} className="d-flex">
                            <div className="card photo-container" onClick={() => handlePhotoClick(photo.id)}>
                                <img
                                    src={photo.imageUri.startsWith('/') ? `https://storage.cosplaycorps.com${photo.imageUri}` : photo.imageUri}
                                    alt={photo.title}
                                    className="card-img-top scaled-img"
                                />
                            </div>
                        </div>
                    );
                }
            })}
        </Masonry>
    );
};