import React, { useState } from 'react';
import ConventionTab from './ConventionTab';
import PhotoshootTab from './PhotoshootTab';
import { QuickShoot } from '../../interfaces/QuickShoot';
import { Convention } from '../../api/ApiModels/Models';

type Tab = 'convention' | 'photoshoot';

interface EventsProps{
  conventionData: Convention[] | null;
  quickShootData: QuickShoot[] | null;
}

const Events: React.FC<EventsProps> = ({conventionData, quickShootData}) => {
  const [activeTab, setActiveTab] = useState<Tab>('photoshoot');

  const handleTabClick = (tab: Tab) => {
    setActiveTab(tab);
  };

  return (
    <div className='container'>
      <div className='mt-4 pt-2'>
        <div className='d-lg-flex align-items-center justify-content-between'>
          <h3 className='ff-manrope common-head'>Upcoming Events</h3>
          {/* <button className="w-lg-100 text-white border-0 mt-lg-0 mt-4 py-3 px-4 hover-btn rounded-4 w-full ff-manrope font-semibold bg-pink">
            Add New Event
          </button> */}
        </div>
        <div className='d-flex align-items-center gap-tabs mt-4 tabs'>
          <div
            className={`tab-item ff-manrope fw-medium text-sm ${activeTab === 'convention' ? 'active' : 'light'}`}
            onClick={() => handleTabClick('convention')}
          >
            Plans
          </div>
          <div
            className={`tab-item ff-manrope fw-medium text-sm ${activeTab === 'photoshoot' ? 'active' : 'light'}`}
            onClick={() => handleTabClick('photoshoot')}
          >
            Photoshoots
          </div>
        </div>
        <div className='tab-content-wrapper mt-4'>
          <div className={`tab-content ${activeTab === 'convention' ? 'active-tab' : ''}`}>
            {activeTab === 'convention' && <ConventionTab conventions={conventionData ?? null} />}
          </div>
          <div className={`tab-content ${activeTab === 'photoshoot' ? 'active-tab' : ''}`}>
            {activeTab === 'photoshoot' && <PhotoshootTab quickShootData={quickShootData}/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
