import { Convention } from "../../api/ApiModels/Models";

const LOCAL_STORAGE_KEY = 'conventions';

export const saveConventionsToLocalStorage = (conventions: Convention[]) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(conventions));
};

export const loadConventionsFromLocalStorage = (): Convention[] => {
  const data = localStorage.getItem(LOCAL_STORAGE_KEY);
  return data ? JSON.parse(data) : [];
};  