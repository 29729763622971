import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import LoginButton from '../Auth/login'
import LogoutButton from '../Auth/logout'
import { NavLink } from 'react-router-dom'

const LandingNavBar = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const { isAuthenticated } = useAuth0()

  return (
    <div className="bg-grey pt-2 pt-sm-4">
      <Container className="custom-container px-3 mx-auto">
        <Navbar className="bg-white nav-shadow" expand="lg">
          <Navbar.Brand href="#home">
            <img
              src="/images/CosplayCorpsLogo.png"
              width="86"
              height="74"
              className="logo"
              alt="Cosplay Corps logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleShow}
            className="custom-toggler"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto d-none d-lg-flex gap-5 align-items-center">
              <Nav.Link
                as={NavLink}
                to={"/dashboard"}
                className="ff-manrope nav fw-medium dark text-sm"
              >
                HOME
              </Nav.Link>
              <Nav.Link
                href="#about"
                className="ff-manrope nav fw-medium dark text-sm"
              >
                ABOUT US
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to={"/gallery"}
                className="ff-manrope nav fw-medium dark text-sm"
              >
                PHOTOS
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Nav className="ml-auto d-none d-lg-flex align-items-center">
            {isAuthenticated ? (
              <LogoutButton />
            ) : (
              <LoginButton />
            )}
          </Nav>
        </Navbar>
      </Container>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <img src="/images/cosplaycorpslogo.png" alt="cosplay" className="logo" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column text-center">
            <Nav.Link
              as={NavLink}
              to={"/"}
              className="ff-manrope nav fw-medium dark text-sm"
              onClick={handleClose}
            >
              HOME
            </Nav.Link>
            <Nav.Link
              href="#about"
              className="ff-manrope nav fw-medium dark text-sm"
              onClick={handleClose}
            >
              ABOUT US
            </Nav.Link>

            <Nav.Link
              as={NavLink}
              to={"/gallery"}
              className="ff-manrope nav fw-medium dark text-sm"
            >
              PHOTOS
            </Nav.Link>
            {isAuthenticated ? (
              <LogoutButton />
            ) : (
              <LoginButton />
            )}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default LandingNavBar
