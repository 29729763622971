// src/pages/MyDashboard.tsx
import React, { useState, useEffect } from 'react';
import DashboardApiProcessor from '../../api/DashboardApi';
import { useAuth0 } from '@auth0/auth0-react';
import { QuickShoot } from '../../interfaces/QuickShoot';
import DashBoardChart from '../Profile/DashBoardChart';
import MyImages from '../../components/MyProfile/MyImages';
import Events from '../Profile/Events';
import { Photograph } from '../Photographs/Models/Photograph';
// import PhotoGrapher from '../Profile/PhotoGrapher';
import { CosplayCorpsUser, SocialMediaOptions } from '../../interfaces/Profile';
import { Convention } from '../../api/ApiModels/Models';

export interface DashboardData {
  metrics: MetricData,
  user: CosplayCorpsUser,
  quickShoots: QuickShoot[],
  upcomingScheduledEvents: Convention[],
  lastUploadDate: Date | null,
  myPhotos: Photograph[],
  socialMedia: SocialMediaOptions
}

export interface MetricForDay{
  viewCount: number,
  weekDay: string
}

export interface MetricData {
  totalNewViews: number,
  upcomingEvents: number,
  bookingRequests: number,
  lastSevenDaysViews: MetricForDay[]
}

const MyDashboard: React.FC = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [dashboardData, setDashboardData] = useState<DashboardData | null>();

  useEffect(() => {
    const fetchData = async () => {
      if (user && user[`https://cosplaycorps.com/roles`]) {
        setUserRoles(user[`https://cosplaycorps.com/roles`]);
      }

      const processor = new DashboardApiProcessor(getAccessTokenSilently);
      setDashboardData(await processor.fetchDashboardData());
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, []);

  if (!isAuthenticated) {
    return <div>Please log in to view your dashboard.</div>;
  }

  return (!dashboardData ? <>Loading...</> :
    <div>
      <DashBoardChart dashboardData={dashboardData} />
      <div className=' mt-3 mt-lg-0'>
        <MyImages images={dashboardData?.myPhotos ?? null} showBottomText={true} headingText="Images of the Week" />
      </div>
      <Events quickShootData={dashboardData.quickShoots} conventionData={dashboardData?.upcomingScheduledEvents ?? null} />
      {/* <BookingRequest /> */}
    </div>
  );
};

export default MyDashboard;
