// src/api/PhotoApiProcessor.ts
import { GetTokenSilentlyOptions } from '@auth0/auth0-react';

export interface UserDetails {
  completedSignUp: boolean;
  Auth0Id: string;
}

class SuggestionApiProcessor {
  private baseUrl = `${process.env.REACT_APP_COSPLAYCORPS_API_URL!}`;
  private domain = process.env.REACT_APP_AUTH0_DOMAIN!;
  private audience = process.env.REACT_APP_AUTH0_AUDIENCE!;
  private getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>;

  constructor(getAccessTokenParam: (options?: GetTokenSilentlyOptions) => Promise<string>) {
    this.getAccessToken = getAccessTokenParam;
  }

  static processEnrollment: any;


  async fetchUserNames(text: string) {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });
    //We dont need to look up tags that are less than 3 characters in space. This is to reduce query volume.
    if (checkLength(text, 3)) {
      return [];
    }
    const response = await fetch(`${this.baseUrl}/Search/User/${text}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data as string[];
  }

  async fetchConventionNames(text: string, includePast: boolean): Promise<string[]> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });
    //We dont need to look up tags that are less than 3 characters in space. This is to reduce query volume.
    if (checkLength(text, 3)) {
      return [];
    }
    const response = await fetch(`${this.baseUrl}/Search/ConventionNames/${text}?includePast=${includePast}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data as string[];
  }

  async fetchCosplayerUserNames(text: string): Promise<string[]> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });
    //We dont need to look up tags that are less than 3 characters in space. This is to reduce query volume.
    if (checkLength(text, 3)) {
      return [];
    }
    const response = await fetch(`${this.baseUrl}/Search/Cosplayer/${text}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data as string[];
  }

  async fetchPhotographerUserNames(text: string): Promise<string[]> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });
    //We dont need to look up tags that are less than 3 characters in space. This is to reduce query volume.
    if (checkLength(text, 3)) {
      return [];
    }
    const response = await fetch(`${this.baseUrl}/Search/Photographer/${text}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data as string[];
  }


  async fetchTagNames(text: string): Promise<string[]> {
    const accessToken = await this.getAccessToken({
      authorizationParams: {
        audience: `https://${this.domain}/api/v2/`,
      },
    });
    //We dont need to look up tags that are less than 3 characters in space. This is to reduce query volume.
    if (text.length < 3) {
      text = "";
    }
    const response = await fetch(`${this.baseUrl}/Search/TagNames/${text}`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data as string[];
  }
}

function checkLength(stringToCheck: string, lengthMinimum: number) {
  return stringToCheck && (stringToCheck.length < lengthMinimum);
}

export default SuggestionApiProcessor;
