// src/components/TermsAndConditions.tsx

import React from 'react';
import { Form, Button } from 'react-bootstrap';

interface TermsAndConditionsProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  nextStep: () => void;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ formData, setFormData, nextStep }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      termsAccepted: event.target.checked,
    });
    if (event.target.checked) {
      nextStep();
    } else {
      alert("You must accept the terms and conditions to proceed.");
    }
  };

  return (
    <div className="container terms-and-conditions">
      <h2 className='text-center'>Terms and Conditions</h2>
      <div>
        <iframe style={{border: 2, width: "100%", height: "400px"}} src='https://app.termly.io/policy-viewer/policy.html?policyUUID=626d5b32-40aa-433c-80b9-c218f6707577'></iframe>
      </div>
      <p>You must accept our terms and conditions before proceeding.</p>
      <Form>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="I have read and accept the terms and conditions"
            checked={formData.termsAccepted || false}
            onChange={handleCheckboxChange}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default TermsAndConditions;