import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { MetricForDay } from '../User/MyDashboard';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = (minY: number, maxY: number) => ({
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: false,
      min: Math.floor(minY * 0.9), // Set minimum to 90% of the smallest data point
      max: Math.ceil(maxY * 1.1), // Set maximum to 110% of the largest data point
      grid: {
        display: true,
        drawBorder: false,
      },
      ticks: {
        stepSize: 1,
        callback: (value: number | string) => {
          const numValue = Number(value);

          // Display values in 'K' format if >= 1000, otherwise as whole numbers
          if (numValue >= 1000) {
            return `${Math.round(numValue / 1000)}K`; // Round down to nearest thousand
          }

          // Display whole number without formatting
          return numValue;
        },
      },
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
      external: (context: any) => {
        const tooltipModel = context.tooltip;
        const chart = context.chart;

        let tooltipEl = document.getElementById('chartjs-tooltip');
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.style.opacity = '0';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }

        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        if (tooltipModel.dataPoints) {
          const dataPoint = tooltipModel.dataPoints[0];
          const value = dataPoint.raw;
          const body = `<tr class="text-center bg-white p-5"><td><span class="pink">${value < 1000 ? value : (value / 1000) + "K"}</span> <br/> Total View(s)</td></tr>`;
          const tableRoot = tooltipEl.querySelector('table');
          if (tableRoot) tableRoot.innerHTML = body;

          const position = chart.canvas.getBoundingClientRect();
          const tooltipWidth = tooltipEl.clientWidth;
          const tooltipHeight = tooltipEl.clientHeight;
          const elementX = dataPoint.element.x;
          const elementY = dataPoint.element.y;

          tooltipEl.style.opacity = '1';
          tooltipEl.style.left = `${position.left + window.pageXOffset + elementX - tooltipWidth / 2}px`;
          tooltipEl.style.top = `${position.top + window.pageYOffset + elementY - tooltipHeight - 10}px`;
        }
      },
    },
    legend: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
    mode: 'index' as const,
  },
  responsive: true,
  maintainAspectRatio: false,
});


interface MainChartProps{
  dataValues: MetricForDay[]
}

const MainChart: React.FC<MainChartProps> = ({dataValues}) => {
  const [chartDataValues, setChartDataValues] = useState<any | null>(null);
  const [minY, setMinY] = useState<number>(0);
  const [maxY, setMaxY] = useState<number>(0);

  useEffect(() => {
    // Extract labels (weekDay) and data (viewCount) from the dataValues prop
    const labels = dataValues.map(item => item.weekDay);
    const dataPoints = dataValues.map(item => item.viewCount);
    // Calculate min and max Y values
    const minDataValue = Math.min(...dataPoints);
    const maxDataValue = Math.max(...dataPoints);
    setMinY(minDataValue);
    setMaxY(maxDataValue);

    var data = {
      labels: labels,
      datasets: [
        {
          label: 'Total Views',
          data: dataPoints,
          fill: true,
          backgroundColor: (context: any) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
            gradient.addColorStop(0, 'rgba(255, 99, 132, 0.2)');
            gradient.addColorStop(1, 'rgba(255, 99, 132, 0.0)');
            return gradient;
          },
          borderColor: '#ff6384',
          tension: 0.3,
          pointBackgroundColor: '#ff6384',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: '#ff6384',
          hoverRadius: 7,
        },
      ],
    };

    setChartDataValues(data);
  }, [dataValues]); // Update when dataValues changes
  
  return ( chartDataValues &&
    <div className="main-chart-container h-full overflow-hidden" style={{ position: 'relative' }}>
      <Line data={chartDataValues} options={options(minY, maxY)} />
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&display=swap');

          .custom-tooltip {
            background: white;
            border-radius: 3px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
            padding: 10px;
            pointer-events: none;
            transform: translate(-50%, -100%);
            transition: opacity 1s ease;
            text-align: center;
          }

          .ff-manrope {
            font-family: 'Manrope', sans-serif;
          }

          .pink {
            color: #ff6384;
            font-weight: 600;
          }
        `}
      </style>
    </div>
  );
};

export default MainChart;
