interface SocialMediaIconProps {
    socialMediaType: string;
    userName: string;
}

const socialDetails = {
    YouTube: {
        src: "/svg/logo-youtube.svg",
        alt: "YouTube",
        link: "https://www.youtube.com/@",
    },
    Twitch: {
        src: "/svg/logo-twitch.svg",
        alt: "Twitch",
        link: "https://www.twitch.tv/",
    },
    LinkedIn: {
        src: "/svg/logo-linkedin.svg",
        alt: "LinkedIn",
        link: "https://www.linkedin.com/in/",
    },
    Facebook: {
        src: "/svg/logo-facebook.svg",
        alt: "Facebook",
        link: "https://www.facebook.com/",
    }
};

export const SocialMediaIcon: React.FC<SocialMediaIconProps> = ({ socialMediaType, userName }) => {
    var socialToUse;
    if (socialMediaType == "YouTube") {
        socialToUse = socialDetails.YouTube;
    }else if (socialMediaType == "Twitch") {
        socialToUse = socialDetails.Twitch;
    }else if (socialMediaType == "LinkedIn") {
        socialToUse = socialDetails.LinkedIn;
    }
    else{ //(socialMediaType == "2") {
        socialToUse = socialDetails.Facebook;
    }


    return (
        <div className="bg-cultured social-icon-box d-flex align-items-center justify-content-center w-50">
            <a
                href={socialToUse?.link + userName}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img src={socialToUse?.src} alt={socialToUse?.alt} />
            </a>
        </div>
    )
}