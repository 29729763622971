import React from "react";
import { SubscribeNowSvg } from "../common/Icons";

const MySubscribeContent: React.FC<{ handleFreeTrialClick: () => void }> = ({ handleFreeTrialClick }) => {
  return (
    <>
      <div className="container container-custom  py-4 py-lg-4 px-lg-3 mx-auto mb-5 mb-md-0">
        <div className="profile-box-main box-shadow-common p-4 py-md-5 text-center d-flex flex-column justify-content-center align-items-center mb-5 mb-md-0">
          <SubscribeNowSvg />

          <h4
            className="font-semibold text-lg text-dark custom_mw_subscribe_h 
          pt-2 pt-md-4 mt-2 ff-manrope"
          >
            Right Now No Subscribe Content Display
          </h4>
          <button onClick={handleFreeTrialClick} className="mb-2 mb-md-2 py-3 subscribe_btn mt-3 mt-md-4 bg-transparent text-s font-semibold ff-manrope">
            ADD CONTENT
          </button>
        </div>
      </div>
    </>
  );
};

export default MySubscribeContent;
